import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Provider, Subscribe } from 'unstated';
import Helmet from 'react-helmet';
import AppContainer from '../../containers/AppContainer';
import ProductContainer from '../../containers/ProductContainer';
import ProductDetails from './ProductDetails';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import ShareButtons from '../../components/ShareButtons';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFound from '../Statuses/NotFound';
import Hidden from '@material-ui/core/Hidden';
import { spinnerStyle } from '../../styles';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2) + 37, // Account for footer height.
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2) + 54 // Account for footer height (double height on xs viewport).
        }
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    title: {
        flex: 1,
        fontFamily: 'Open Sans Condensed',
        fontSize: '2.5rem',
        letterSpacing: 'unset',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: '2rem'
        }
    },
    shareContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    container: {
        display: 'flex'
    },
    ...spinnerStyle(theme)
}));

function canGoBack(location) {
    const { state } = location;
    return state != null && Boolean(state.canGoBack);
}

function onGoBack(history) {
    history.goBack();
}

function Product({ storeSlug, productSlug, storeSearchService, productSearchService }) {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [notFound, setNotFound] = useState();
    const productContainer = useRef(new ProductContainer(storeSearchService, productSearchService));

    useEffect(() => {
        (async function () {
            try {
                await productContainer.current.load(storeSlug, productSlug, false);
            } catch (error) {
                if (error.response.status === 404) {
                    setNotFound(true);
                }
            }
        })();
    }, [storeSlug, productSlug, productSearchService]);

    return <div className={classes.root}>
        <Header storeSearchService={storeSearchService} />
        {!notFound && <Provider inject={[productContainer.current]}>
            <Subscribe to={[AppContainer, ProductContainer]}>
                {(ac, pc) => Boolean(pc.state.product) ?
                    <Fragment>
                        <Helmet>
                            <meta name="description" content={pc.state.product.meta.description} />
                            {Boolean(pc.state.product.meta.description) && <meta name="keywords" content={pc.state.product.meta.keywords} />}
                            <meta property="og:url" content={document.location.href} />
                            <meta property="og:title" content={pc.state.product.meta.title} />
                            <meta property="og:description" content={pc.state.product.meta.description} />
                            <title>{pc.state.product.meta.title}</title>
                        </Helmet>
                        <Grid container justify="center" className={classes.gridContainer}>
                            <Hidden smUp>
                                <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.shareContainer}>
                                    <ShareButtons facebook
                                        twitter
                                        email
                                        title={`${pc.state.product.name} is available on Mavitra.`}
                                        url={document.location.href}
                                        size={48}
                                        urlShortener={ac.shortenUrl}
                                        onShare={utms => ac.onShareProductClicked(pc.state.product.id, utms)} />
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.titleContainer}>
                                <Hidden xsDown>
                                    {canGoBack(location) && <IconButton color="primary" style={{ marginRight: 8 }} onClick={() => onGoBack(history)}>
                                        <BackIcon fontSize="large" />
                                    </IconButton>}
                                </Hidden>
                                <Typography variant="h1" className={classes.title}>{pc.state.product.name}</Typography>
                                <Hidden xsDown>
                                    <ShareButtons facebook
                                        twitter
                                        email
                                        title={`${pc.state.product.name} is available on Mavitra.`}
                                        url={document.location.href}
                                        size={48}
                                        urlShortener={ac.shortenUrl}
                                        onShare={utms => ac.onShareProductClicked(pc.state.product.id, utms)} />
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                                <Paper className={classes.container}>
                                    <ProductDetails
                                        store={pc.state.store}
                                        product={pc.state.product}
                                        onOpenStore={slug => history.push({
                                            pathname: `/stores/${slug}`,
                                            state: { canGoBack: true }
                                        })}
                                    // onOpenSimilarProduct={(id, slug) => this.onOpenSimilarProduct(ac, pc.state.product.id, id, slug)}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Fragment> :
                    <CircularProgress className={classes.spinner} size={90} thickness={2.4} />}
            </Subscribe>
        </Provider>}
        {notFound && <NotFound />}
        <Footer solid />
    </div>;
}

Product.propTypes = {
    storeSlug: PropTypes.string.isRequired,
    productSlug: PropTypes.string.isRequired,
    storeSearchService: PropTypes.object.isRequired,
    productSearchService: PropTypes.object.isRequired
};

export default Product;