import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const styles = theme => ({
    facet: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textTransform: 'capitalize'
    },
    selected: {
        backgroundColor: theme.palette.divider,
        fontWeight: 500
    }
});

class FacetList extends Component {
    static propTypes = {
        facets: PropTypes.object.isRequired,
        selected: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired
    };

    render() {
        const { classes, facets, selected, onClick, ...rest } = this.props;

        return <List disablePadding dense {...rest}>
            {Object.keys(facets).map(k => {
                const isSelected = selected.includes(k);

                return <ListItem key={k}
                    button
                    classes={{ root: classNames(classes.facet, { [classes.selected]: isSelected }) }}
                    onClick={_ => onClick(k)}>
                    <ListItemText primary={`${k} (${facets[k]})`}
                        primaryTypographyProps={{
                            variant: 'caption'
                        }} />
                </ListItem>;
            })}
        </List>;
    }
}

export default withStyles(styles)(FacetList);