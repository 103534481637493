import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    }
});

class NotificationSettingsForm extends Component {
    static propTypes = {
        notificationsEnabled: PropTypes.bool.isRequired,
        onNotificationsEnabledChange: PropTypes.func.isRequired
        //onChange: PropTypes.func.isRequired
    };

    // onCheckedChange = name => event => {
    //     this.props.onChange({
    //         [name]: event.target.checked,
    //     });
    // };

    render() {
        const { classes, notificationsEnabled, onNotificationsEnabledChange } = this.props;

        return <div className={classes.root}>
            <FormControlLabel control={
                <Switch color="primary"
                    checked={notificationsEnabled}
                    onChange={e => onNotificationsEnabledChange(e.target.checked)} />}
                label={notificationsEnabled ? 'Notifications are enabled' : 'Notifications are disabled'} />
            <Typography variant="caption" gutterBottom>
                You will {!notificationsEnabled && 'not '}receive notifications from us. {!notificationsEnabled && 'We may still send you important notifications regarding your account.'}
            </Typography>
        </div>;
    }
}

export default withStyles(styles)(NotificationSettingsForm);