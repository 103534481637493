import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../../containers/AppContainer';
import StoreContainer from '../../containers/StoreContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import StarRating from '../../components/StarRating';
import ImageGrid from '../../components/ImageGrid';
import withStyles from '@material-ui/core/styles/withStyles';
import Lightbox from 'react-images';
import NearbyStoreCard from '../../components/NearbyStoreCard';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star';
import StoreContactActions from '../../components/StoreContactActions';
import StoreFeatureList from '../../components/StoreFeatureList';
import { toHtmlString } from '../../helpers';
import encodeUrl from 'encodeurl';
import classNames from 'classnames';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { paragraphsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    hero: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        marginBottom: theme.spacing(1),
        width: '100%',
        height: '250px',
        [theme.breakpoints.up('md')]: {
            height: '300px'
        },
        [theme.breakpoints.up('lg')]: {
            height: '350px'
        },
        [theme.breakpoints.up('xl')]: {
            height: '400px'
        }
    },
    logoContainer: {
        display: 'flex',
        padding: theme.spacing(1)
    },
    logo: {
        alignSelf: 'center',
        maxHeight: '200px',
        maxWidth: '100%'
    },
    imageModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        },
        '& > img': {
            outline: 'none',
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    map: {
        border: 0,
        margin: 0,
        padding: 0,
        flexGrow: 1,
        minHeight: 300,
        width: '100%'
    },
    block: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'stretch'
        }
    },
    gutterBottom: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    gutterTop: {
        marginTop: theme.spacing(2)
    },
    ...paragraphsStyle(theme)
});

class StoreDetails extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired,
        onOpenShoppingLocation: PropTypes.func.isRequired,
        onOpenNearbyStore: PropTypes.func.isRequired
    };

    state = {
        addingToFavourites: false,
        imageIndex: 0,
        isImageOpen: false
    };

    constructor(props) {
        super(props);

        this.onCloseImage = this.onCloseImage.bind(this);
        this.onAddStoreToFavourites = this.onAddStoreToFavourites.bind(this);
    }

    getOpenStatusText() {
        switch (this.props.store.isOpen) {
            case true: return 'Open now';
            case false: return 'Closed now';
            case null: return "We're not sure";
            default: return 'Checking...';
        }
    }

    onOpenImage(ac, image, index) {
        this.setState({
            imageIndex: index,
            isImageOpen: true
        });

        ac.onStoreGalleryViewed(this.props.store.id);
    }

    onCloseImage() {
        this.setState({ isImageOpen: false });
    }

    async onAddStoreToFavourites(ac, sc) {
        this.setState({ addingToFavourites: true });

        try {
            const customerId = ac.getClaim('sub');
            await sc.addStoreToFavourites(customerId);
        } finally {
            this.setState({ addingToFavourites: false });
        }
    }

    renderStoreDetails(store) {
        const { classes, width, googleApiKey, onOpenShoppingLocation, onOpenNearbyStore } = this.props;
        const { addingToFavourites, imageIndex, isImageOpen } = this.state;

        // Convert store images into lightbox format.
        const images = store.images.map(img => ({
            src: img.imageUrl,
            caption: img.description
        }));

        let imageGridCols = 4.5;
        let imageGridCellHeight = 175;

        for (const breakpoint of ['md', 'sm', 'xs']) {
            if (isWidthDown(breakpoint, width)) {
                imageGridCols--;
                imageGridCellHeight -= 25;
            }
        }

        const logo = <img className={classes.logo} src={store.logoImageUrl || '/images/no-image.png'} alt={`${store.name} logo`} title={`${store.name} logo`} />;

        return <Fragment>
            <Hidden xsDown>
                {Boolean(store.heroImageUrl) && <div className={classes.hero} style={{ backgroundImage: `url("${store.heroImageUrl}")` }}>
                    <Paper square elevation={4} className={classes.logoContainer}>
                        {logo}
                    </Paper>
                </div>}
                {!Boolean(store.heroImageUrl) && <img className={classNames(classes.logo, classes.gutterBottom)} src={store.logoImageUrl || '/images/no-image.png'} alt={`${store.name} logo`} title={`${store.name} logo`} />}
            </Hidden>
            <Hidden smUp>
                {logo}
            </Hidden>
            {!Boolean(store.heroImageUrl) && <Divider className={classes.gutterBottom} />}
            <Subscribe to={[AppContainer, StoreContainer]}>
                {(ac, sc) => <Fragment>
                    {store.images.length > 0 && <div className={classes.gutterBottom}>
                        <ImageGrid images={store.images}
                            cellHeight={imageGridCellHeight}
                            cols={imageGridCols}
                            onSelect={(img, idx) => this.onOpenImage(ac, img, idx)} />
                        <Lightbox images={images}
                            currentImage={imageIndex}
                            isOpen={isImageOpen}
                            backdropClosesModal={true}
                            showImageCount={false}
                            onClickNext={() => this.setState({ imageIndex: imageIndex + 1 })}
                            onClickPrev={() => this.setState({ imageIndex: imageIndex - 1 })}
                            onClose={this.onCloseImage} />
                    </div>}
                    {ac.hasToken() && <Fragment>
                        {store.isFavourite && <Typography align="center" display="block" color="primary" variant="overline" gutterBottom>
                            This is one of your favourite stores
                        </Typography>}
                        {!store.isFavourite && <BusyButton color="primary"
                            busy={addingToFavourites}
                            style={{ marginBottom: 8, alignSelf: 'center' }}
                            onClick={() => this.onAddStoreToFavourites(ac, sc)}>
                            <StarIcon style={{ marginRight: 8 }} />Add to your favourites
                        </BusyButton>}
                    </Fragment>}
                    <div className={classes.gutterBottom}>
                        <StoreContactActions store={store}
                            onPhoneClick={() => ac.onStorePhoneClicked(store.id)}
                            onWebsiteClick={() => ac.onStoreWebsiteClicked(store.id)}
                            onMapClick={() => ac.onStoreMapClicked(store.id)}
                            onDirectionsClick={() => ac.onStoreDirectionsClicked(store.id)} />
                    </div>
                </Fragment>}
            </Subscribe>
            <Divider className={classNames(classes.gutterBottom)} />
            {Boolean(store.description) && <div className={classNames(classes.paragraphs, classes.gutterBottom)}>
                <Typography variant="overline">About</Typography>
                {toHtmlString(store.description)}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Open/Closed</Typography>
                <Typography>{this.getOpenStatusText()}</Typography>
            </div>
            {Boolean(store.rating) &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Average rating</Typography>
                    <StarRating rating={store.rating} />
                </div>}
            {store.features.length > 0 &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Features &amp; services</Typography>
                    <StoreFeatureList detailed features={store.features} />
                </div>}
            {Boolean(store.shoppingLocation) && <div className={classNames(classes.block, classes.gutterBottom)}>
                <Typography variant="overline">Located within</Typography>
                <Typography>{store.shoppingLocation}</Typography>
                {Boolean(store.shoppingLocationSlug) &&
                    <Button className={classes.button}
                        component="a"
                        href={`${window.location.origin}/shopping-locations/${store.shoppingLocationSlug}`}
                        color="primary"
                        size="small"
                        onClick={e => {
                            e.preventDefault();
                            onOpenShoppingLocation(store.shoppingLocationSlug);
                        }}>
                        See more about this location
                    </Button>}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Address</Typography>
                <Typography>{store.address}</Typography>
            </div>
            <div className={classes.gutterBottom}>
                {Boolean(store.googlePlaceId) &&
                    <iframe title="map"
                        frameBorder="0"
                        className={classes.map}
                        src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:${store.googlePlaceId}`}>
                    </iframe>}
                {!Boolean(store.googlePlaceId) &&
                    <iframe title="map"
                        frameBorder="0"
                        className={classes.map}
                        src={encodeUrl(`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${store.address}`)}>
                    </iframe>}
            </div>
            {store.nearbyStores.length > 0 && <div className={classes.gutterBottom}>
                <Typography variant="overline">Nearby here</Typography>
                <Grid container spacing={2}>
                    {store.nearbyStores.map(nearbyStore => <Grid key={nearbyStore.id} item xs={12} sm={6} lg={4}>
                        <NearbyStoreCard store={nearbyStore} onSelect={() => onOpenNearbyStore(nearbyStore.id, nearbyStore.slug)} />
                    </Grid>)}
                </Grid>
            </div>}
        </Fragment>;
    }

    render() {
        const { classes, store } = this.props;

        return <div className={classes.root}>
            {this.renderStoreDetails(store)}
        </div>;
    }
}

export default withStyles(styles)(withWidth()(StoreDetails));