import ApiService from './ApiService';
import axios from 'axios';

export default class CustomerService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getById(id) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${id}`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async update(customerId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getFavouriteStores(customerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/favourite-stores`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeFavouriteStore(customerId, favouriteStoreId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/favourite-stores/${favouriteStoreId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getShoppingLists(customerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createShoppingList(customerId, shoppingList) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists`;
        const response = await axios.post(url, shoppingList, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateShoppingList(customerId, shoppingListId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists/${shoppingListId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeShoppingList(customerId, shoppingListId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists/${shoppingListId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async addShoppingListItem(customerId, shoppingListId, item) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists/${shoppingListId}/items`;
        const response = await axios.post(url, item, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async updateShoppingListItem(customerId, shoppingListId, shoppingListItemId, changes) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists/${shoppingListId}/items/${shoppingListItemId}`;
        const response = await axios.patch(url, changes, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async removeShoppingListItem(customerId, shoppingListId, shoppingListItemId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/shopping-lists/${shoppingListId}/items/${shoppingListItemId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }

    async getSavedSearches(customerId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/saved-searches`;
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createSavedSearch(customerId, savedSearch) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/saved-searches`;
        const response = await axios.post(url, savedSearch, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }

    async removeSavedSearch(customerId, savedSearchId) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/customers/${customerId}/saved-searches/${savedSearchId}`;
        const response = await axios.delete(url, { headers });

        return {
            ok: response.status === 204
        };
    }
}