import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Footer from '../../components/Footer';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router';

const styles = theme => ({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        marginTop: theme.spacing(3)
    }
});

const NotFound = props => {
    const { classes } = props;

    // TODO: Add background image (of empty shelves?)

    return <div className={classes.root}>
        <Typography variant="h2" align="center" display="block" gutterBottom>Uh oh, we can't find that page.</Typography>
        <Typography variant="body1" align="center" display="block" gutterBottom>
            Somebody must have put it back on the wrong shelf.
        </Typography>
        <Button className={classes.button} variant="contained" color="primary" onClick={() => props.history.push('/')}>
            Try searching again
        </Button>
        <Footer />
    </div>;
};

export default withRouter(withStyles(styles)(NotFound));