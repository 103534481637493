import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(1),
        zIndex: theme.zIndex.appBar
    },
    solid: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    }
});

class Footer extends Component {
    static propTypes = {
        solid: PropTypes.bool.isRequired
    };

    static defaultProps = {
        solid: false
    };

    render() {
        const { classes, children, solid, className, ...rest } = this.props;
        const developedBy = <Fragment>Developed by <Link color="inherit" href="https://mavitra.dev" target="_blank" rel="noopener">Mavitra Services</Link></Fragment>;
        const copyright = <Fragment>Copyright &copy; {new Date().getFullYear()} Mavitra Pty Ltd</Fragment>;

        return <footer className={classNames(classes.root, { [classes.solid]: solid }, className)} {...rest}>
            {children || <div>
                <Hidden xsDown>
                    <Typography align="center" color="inherit" display="block" variant="caption">
                        {developedBy}. {copyright}.
                    </Typography>
                </Hidden>
                <Hidden smUp>
                    <Typography align="center" color="inherit" display="block" variant="caption">
                        {developedBy}
                    </Typography>
                    <Typography align="center" color="inherit" display="block" variant="caption">
                        {copyright}
                    </Typography>
                </Hidden>
            </div>}
        </footer>;
    }
}

export default withStyles(styles)(Footer);