import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Star from '@material-ui/icons/Star';
import StarHalf from '@material-ui/icons/StarHalf';
import StarBorder from '@material-ui/icons/StarBorder';
import yellow from '@material-ui/core/colors/yellow';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    stars: {
        color: yellow[600]
    }
};

class StarRating extends Component {
    static propTypes = {
        rating: PropTypes.number,
    };

    render() {
        const { classes, rating, ...rest } = this.props;

        if (rating == null) {
            return null;
        }

        const ratingInt = parseInt(rating);
        const fraction = (rating % 1).toFixed(1);

        const stars = Array(ratingInt).fill(Star);

        if (fraction >= 0.5) {
            stars.push(StarHalf);
        }

        while (stars.length < 5) {
            stars.push(StarBorder);
        }

        return <div className={classes.stars} {...rest}>
            {stars.map((S, i) => <S key={i} color="inherit" />)}
        </div>;
    }
}

export default withStyles(styles)(StarRating);