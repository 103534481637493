import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateAvailabilityNotificationForm extends Component {
    static propTypes = {
        emailAddress: PropTypes.string.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    static defaultProps = {
        emailAddress: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: props.emailAddress,
            captcha: null,
            errors: {},
            error: null,
            busy: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { emailAddress, captcha } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                emailAddress,
                captcha
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors,
                    error: null,
                    busy: false
                });
            } else {
                this.setState({
                    errors: {},
                    error: error.response.data,
                    busy: false
                });
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { emailAddress, captcha, errors, error, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Typography gutterBottom>
                We can send you an email when what you're after becomes available in one of our stores. We'll only notify you <em>once</em> and we <em>never</em> give your details to any other party.
            </Typography>

            <TextField
                autoFocus
                id="emailAddress"
                label="Email address"
                required
                fullWidth
                margin="normal"
                type="email"
                value={emailAddress}
                helperText={errors.emailAddress}
                error={Boolean(errors.emailAddress)}
                onChange={this.onChange('emailAddress')} />

            <Typography gutterBottom variant="caption">
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </Typography>

            <GoogleReCaptcha action="availability_notification" onVerify={captcha => this.setState({ captcha })} />

            {Boolean(error) && <Typography align="center" color="error" className={classes.error}>
                {error}
            </Typography>}

            <div className={classes.drawerActions}>
                <BusyButton disabled={!Boolean(captcha)} busy={busy} variant="contained" color="primary" type="submit">Let me know</BusyButton>
                <Button onClick={onCancel}>No thanks</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateAvailabilityNotificationForm);