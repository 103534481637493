import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LocationOn from '@material-ui/icons/LocationOn';
import LocationOff from '@material-ui/icons/LocationOff';
import uniqueId from 'lodash/uniqueId';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    popper: {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.primary.main} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
            },
        },
        zIndex: theme.zIndex.appBar + 50
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    }
});

class LocationButton extends Component {
    static propTypes = {
        location: PropTypes.string,
        showLocation: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        showLocation: true
    };

    constructor(props) {
        super(props);
        this.arrowElementId = uniqueId('arrow-');
    }

    render() {
        const { classes, location, showLocation, disabled, onClick } = this.props;
        const hasLocation = Boolean(location);

        return <Fragment>
            <IconButton ref={el => this.buttonEl = el}
                disabled={disabled}
                onClick={onClick}>
                {hasLocation ? <LocationOn color="primary" /> : <LocationOff />}
            </IconButton>
            <Popper open={Boolean(this.buttonEl) && hasLocation && showLocation && !disabled}
                anchorEl={this.buttonEl}
                placement="bottom-end"
                className={classes.popper}
                modifiers={{
                    arrow: {
                        enabled: true,
                        element: `#${this.arrowElementId}`
                    }
                }}>
                <Paper className={classes.paper}>
                    <Typography color="inherit" variant="caption">{location}</Typography>
                    <div id={this.arrowElementId} className={classes.arrow} />
                </Paper>
            </Popper>
        </Fragment>;
    }
}

export default withStyles(styles)(LocationButton);