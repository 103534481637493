import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, Subscribe } from 'unstated';
import MyProfileContainer from '../../containers/MyProfileContainer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Person from '@material-ui/icons/Person';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Store from '@material-ui/icons/Store';
import Search from '@material-ui/icons/Search';
import Notifications from '@material-ui/icons/Notifications';
import PersonalDetailsForm from './PersonalDetailsForm';
import ShoppingLists from './ShoppingLists';
import FavouriteStoreList from './FavouriteStoreList';
import SavedSearches from './SavedSearches';
import NotificationSettingsForm from './NotificationSettingsForm';
import SideDrawer from '../../components/SideDrawer';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import CreateShoppingListForm from './CreateShoppingListForm';
import UpdateShoppingListForm from './UpdateShoppingListForm';
import AddShoppingListItemForm from './AddShoppingListItemForm';
import UpdateShoppingListItemForm from './UpdateShoppingListItemForm';
import NotFound from '../Statuses/NotFound';
import { API_DATE_FORMAT } from '../../constants';
import format from 'date-fns/format';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2) + 37, // Account for footer height.
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2) + 54 // Account for footer height (double height on xs viewport).
        }
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    container: {
        display: 'flex'
    },
    list: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    listItemIcon: {
        color: 'inherit',
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        }
    },
    selected: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette.text.primary,
        transition: theme.transitions.create(['background-color', 'color'])
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden', // Critical when child component is a GridList!
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        }
    },
    tabTitle: {
        paddingBottom: theme.spacing(2)
    },
    tabContent: {
        position: 'relative',
        flex: 1
    },
    success: {
        backgroundColor: green[600]
    },
    close: {
        padding: theme.spacing(0.5)
    },
    fab: {
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    fabContainer: {
        paddingBottom: theme.spacing(10)
    }
});

class MyProfile extends Component {
    static propTypes = {
        customerService: PropTypes.object.isRequired,
        storeSearchService: PropTypes.object.isRequired,
        tab: PropTypes.string
    };

    static defaultProps = {
        tab: 'personal-details'
    };

    listItems = {
        'personal-details': {
            text: 'Personal details',
            icon: Person
        },
        'shopping-lists': {
            text: 'Shopping lists',
            icon: ShoppingCart
        },
        'favourite-stores': {
            text: 'Favourite stores',
            icon: Store
        },
        // TODO: Enable once concierge built
        // Use this icon: import Key from '@material-ui/icons/VpnKey';
        // 'concierge': {
        //     text: 'Concierge',
        //     icon: Key
        // },
        'saved-searches': {
            text: 'Saved searches',
            icon: Search
        },
        'notification-settings': {
            text: 'Notification settings',
            icon: Notifications
        }
    };

    constructor(props) {
        super(props);

        const notFound = Object.keys(this.listItems).indexOf(props.tab) < 0;

        this.myProfileContainer = new MyProfileContainer(props.customerService);

        this.state = {
            drawerTitle: '',
            drawerContent: null,
            snackContent: null,
            notFound
        };

        this.closeDrawer = this.closeDrawer.bind(this);
    }

    async componentDidMount() {
        await this.myProfileContainer.load(this.props.customerId);
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    onCreateShoppingList() {
        const drawerContent = <CreateShoppingListForm
            onSave={form => this.createShoppingList(form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Create shopping list', drawerContent);
    }

    onEditShoppingList(sl) {
        const drawerContent = <UpdateShoppingListForm
            shoppingList={sl}
            onSave={form => this.updateShoppingList(sl.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit shopping list', drawerContent);
    }

    onAddShoppingListItem(sl) {
        const drawerContent = <AddShoppingListItemForm
            onSave={form => this.addShoppingListItem(sl.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Add shopping list item', drawerContent);
    }

    onEditShoppingListItem(sl, sli) {
        const drawerContent = <UpdateShoppingListItemForm
            shoppingListItem={sli}
            onSave={form => this.updateShoppingListItem(sl.id, sli.id, form)}
            onCancel={this.closeDrawer} />;

        this.openDrawer('Edit shopping list item', drawerContent);
    }

    async onRemoveShoppingListItem(sl, sli) {
        const confirmed = window.confirm(`Delete item ${sli.name}?`);

        if (confirmed) {
            await this.myProfileContainer.removeShoppingListItem(sl.id, sli.id);
            this.setSnack('Item deleted');

        }
    }

    async onDeleteShoppingList(shoppingList) {
        const confirmed = window.confirm(`Delete shopping list ${shoppingList.name}?`);

        if (confirmed) {
            await this.myProfileContainer.removeShoppingList(shoppingList.id);
            this.setSnack('Shopping list deleted');
        }
    }

    onRunSavedSearch(savedSearch) {
        this.props.history.push('/search' + savedSearch.query);
    }

    async onDeleteSavedSearch(savedSearch) {
        const confirmed = window.confirm(`Delete saved search ${savedSearch.name}?`);

        if (confirmed) {
            await this.myProfileContainer.removeSavedSearch(savedSearch.id);
            this.setSnack('Saved search deleted');
        }
    }

    async createShoppingList(form) {
        const payload = {
            ...form
        };

        if (form.reminderDate != null) {
            payload.reminderDate = format(form.reminderDate, API_DATE_FORMAT);
        }

        const created = await this.myProfileContainer.createShoppingList(payload);

        if (created) {
            this.closeDrawer();
            this.setSnack('Shopping list created');
        }
    }

    async updateShoppingList(shoppingListId, form) {
        const ok = await this.myProfileContainer.updateShoppingList(shoppingListId, form);

        if (ok) {
            this.closeDrawer();
            this.setSnack('Shopping list saved');
        }
    }

    async addShoppingListItem(shoppingListId, form) {
        await this.myProfileContainer.addShoppingListItem(shoppingListId, form);
        this.setSnack('Item added');
    }

    async updateShoppingListItem(shoppingListId, shoppingListItemId, form) {
        const ok = await this.myProfileContainer.updateShoppingListItem(shoppingListId, shoppingListItemId, form);

        if (ok) {
            this.closeDrawer();
            this.setSnack('Item saved');
        }
    }

    handleCloseSnack = () => this.setSnack();

    setSnack(content = null) {
        this.setState({ snackContent: content });
    }

    renderContent() {
        return <Subscribe to={[MyProfileContainer]}>
            {mpc => Boolean(mpc.state.customer) ? this.renderTab(mpc) : this.renderLoading()}
        </Subscribe>;
    }

    renderTab(mpc) {
        switch (this.props.tab) {
            case 'personal-details':
                return this.renderPersonalDetails(mpc);
            case 'shopping-lists':
                return this.renderShoppingLists(mpc);
            case 'favourite-stores':
                return this.renderFavouriteStoreList(mpc);
            case 'concierge':
                return 'Concierge services are coming soon. Watch this space!';
            case 'saved-searches':
                return this.renderSavedSearches(mpc);
            case 'notification-settings':
                return this.renderNotificationSettings(mpc);
            default:
                return 'Unexpected tab key';
        }
    }

    renderLoading() {
        return 'Loading your profile...';
    }

    renderPersonalDetails(mpc) {
        const { customer } = mpc.state;

        const save = async data => {
            await mpc.update(data);
            this.setSnack('Profile saved');
        };

        return <PersonalDetailsForm firstName={customer.firstName}
            canEditFirstName={customer.canEditFirstName}
            lastName={customer.lastName}
            canEditLastName={customer.canEditLastName}
            emailAddress={customer.emailAddress}
            canEditEmailAddress={customer.canEditEmailAddress}
            phoneNumber={customer.phoneNumber}
            canEditPhoneNumber={customer.canEditPhoneNumber}
            locality={customer.locality}
            onChange={c => mpc.setState(prevState => ({ customer: { ...prevState.customer, ...c } }))}
            onSave={d => save(d)} />;
    }

    renderShoppingLists(mpc) {
        const { classes } = this.props;

        return <div className={classNames(classes.tabContent, classes.fabContainer)}>
            <ShoppingLists shoppingLists={mpc.state.shoppingLists}
                onAddItem={sl => this.onAddShoppingListItem(sl)}
                onEditItem={(sl, sli) => this.onEditShoppingListItem(sl, sli)}
                onRemoveItem={(sl, sli) => this.onRemoveShoppingListItem(sl, sli)}
                onEditShoppingList={sl => this.onEditShoppingList(sl)}
                onDeleteShoppingList={sl => this.onDeleteShoppingList(sl)} />
            <Fab className={classes.fab} color="primary" onClick={() => this.onCreateShoppingList()}>
                <AddIcon />
            </Fab>
        </div>;
    }

    async onRequestRemoveFavouriteStore(favouriteStore, mpc) {
        const confirmed = window.confirm(`Remove ${favouriteStore.storeName} from your favourites?`);

        if (confirmed) {
            await mpc.removeFavouriteStore(favouriteStore.id);
        }
    }

    renderSavedSearches(mpc) {
        const { classes } = this.props;

        return <div className={classes.tabContent}>
            <SavedSearches savedSearches={mpc.state.savedSearches}
                onRun={ss => this.onRunSavedSearch(ss)}
                onRemove={ss => this.onDeleteSavedSearch(ss)} />
        </div>;
    }

    renderFavouriteStoreList(mpc) {
        return <FavouriteStoreList stores={mpc.state.favouriteStores}
            onSelectStore={favouriteStore => this.props.history.push({
                pathname: '/stores/' + favouriteStore.slug,
                state: { canGoBack: true }
            })}
            onRemoveStoreFromFavourites={favouriteStore => this.onRequestRemoveFavouriteStore(favouriteStore, mpc)} />;
    }

    renderNotificationSettings(mpc) {
        const { notificationsEnabled } = mpc.state.customer;

        const saveNotificationsEnabled = async notificationsEnabled => {
            mpc.setState(prevState => ({ customer: { ...prevState.customer, notificationsEnabled } }));
            await mpc.updateNotificationsEnabled(notificationsEnabled);
            this.setSnack('Notification settings saved');
        };

        return <NotificationSettingsForm
            notificationsEnabled={notificationsEnabled}
            onNotificationsEnabledChange={enabled => saveNotificationsEnabled(enabled)} />;
    }

    changeTab(slug) {
        this.props.history.push('/my-profile/' + slug);
    }

    render() {
        const { classes, storeSearchService, tab } = this.props;
        const { drawerTitle, drawerContent, snackContent, notFound } = this.state;

        return <div className={classes.root}>
            <Header storeSearchService={storeSearchService} />
            {!notFound && <div style={{ alignSelf: 'stretch', flexShrink: 0, flexGrow: 1 }}>
                <Grid container justify="center" className={classes.gridContainer}>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                            <Typography gutterBottom variant="overline">My profile</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                        <Paper className={classes.container}>
                            <List className={classes.list}>
                                {Object.keys(this.listItems).map(slug => {
                                    const li = this.listItems[slug];
                                    return <ListItem key={slug}
                                        button
                                        selected={tab === slug}
                                        classes={{ selected: classes.selected }}
                                        onClick={() => this.changeTab(slug)}>
                                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                            <li.icon color="inherit" />
                                        </ListItemIcon>
                                        <Hidden smDown>
                                            <ListItemText primary={li.text} primaryTypographyProps={{ color: 'inherit' }} />
                                        </Hidden>
                                    </ListItem>
                                })}
                            </List>
                            <div className={classes.content}>
                                <Hidden mdUp>
                                    <Typography variant="subtitle1" className={classes.tabTitle}>
                                        {this.listItems[tab].text}
                                    </Typography>
                                </Hidden>
                                <Provider inject={[this.myProfileContainer]}>
                                    {this.renderContent()}
                                </Provider>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>}
            <Footer solid />
            <SideDrawer title={drawerTitle}
                anchor="right"
                open={Boolean(drawerContent)}
                onClose={this.closeDrawer}>
                {drawerContent}
            </SideDrawer>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={Boolean(snackContent)}
                autoHideDuration={5000}
                onClose={this.handleCloseSnack}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}                >
                <SnackbarContent
                    className={classes.success}
                    message={<span id="message-id">{snackContent}</span>}
                    action={[<IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseSnack}>
                        <CloseIcon />
                    </IconButton>]}
                />
            </Snackbar>
            {notFound && <NotFound />}
        </div>;
    }
}

export default withRouter(withStyles(styles)(MyProfile));