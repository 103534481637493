import { Container } from 'unstated';

export default class ShoppingLocationContainer extends Container {
    constructor(storeSearchService) {
        super();

        this.storeSearchService = storeSearchService;
    }

    state = {
        shoppingLocation: null
    };

    async load(idOrSlug, loaded = null) {
        const response = await this.storeSearchService.getShoppingLocationDetails(idOrSlug);

        if (response.ok) {
            this.setState({ shoppingLocation: response.data }, loaded ? () => loaded(this.state.shoppingLocation) : null);
        }

        return response.ok;
    }
}