import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class StoreSearchService extends ApiService {
    constructor(apiBaseUrl, getAccessToken, getUserLocation) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
        this.getUserLocation = getUserLocation;
    }

    async getTrendingSearches() {
        const url = `${this.apiBaseUrl}/search/trending-searches`;
        const response = await axios.get(url);

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async getSuggestions(partial) {
        const url = `${this.apiBaseUrl}/search/suggestions`;
        const headers = this.getRequestHeaders(this.getAccessToken());
        const query = { partial };
        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async search(query, brands, categories, features, locations, tags, orderBy, longitude, latitude, radius, pageNumber, pageSize, imageSearch, trending, test = false) {
        const url = `${this.apiBaseUrl}/search`;
        const headers = this.getRequestHeaders(this.getAccessToken());

        const request = {
            query,
            brands,
            categories,
            features,
            locations,
            tags,
            orderBy,
            longitude,
            latitude,
            radius,
            pageNumber,
            pageSize
        };

        if (imageSearch) {
            request.imageSearch = true;
        }

        if (trending) {
            request.trending = true;
        }

        if (test) {
            request.test = true;
        }

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });
        const { data } = response;

        // Search ID is a server-generated key that uniquely identifies this search.
        const searchId = response.headers['x-search-id'];

        if (searchId) {
            // Pass through the search ID on all subsequent requests to correlate user events.
            axios.defaults.headers.common['X-Search-Id'] = searchId;
        }

        return {
            ok: response.status === 200,
            stores: data.stores,
            facets: data.facets,
            totalCount: parseInt(response.headers['x-total-count'], 10),
            expandedStores: data.expandedStores
        };
    }

    async getStoreDetails(storeId, ignoreCache = false) {
        let url = `${this.apiBaseUrl}/search/stores/${storeId}`;
        const userLocation = this.getUserLocation();

        if (userLocation != null) {
            const { latitude, longitude } = userLocation;
            url += `?latitude=${latitude}&longitude=${longitude}`;
        }

        const headers = this.getRequestHeaders(this.getAccessToken());

        if (ignoreCache) {
            headers['Cache-Control'] = 'no-cache';
        }

        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async addStoreToFavourites(customerId, storeId) {
        const url = `${this.apiBaseUrl}/customers/${customerId}/favourite-stores`;
        const headers = this.getRequestHeaders(this.getAccessToken());
        const response = await axios.post(url, { storeId }, { headers });

        return {
            ok: response.status === 200 || response.status === 201,
            data: response.data
        };
    }

    async getShoppingLocationDetails(slug) {
        let url = `${this.apiBaseUrl}/search/shopping-locations/${slug}`;
        const userLocation = this.getUserLocation();

        if (userLocation != null) {
            const { latitude, longitude } = userLocation;
            url += `?latitude=${latitude}&longitude=${longitude}`;
        }

        const headers = this.getRequestHeaders(this.getAccessToken());
        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async createAvailabilityNotification(availabilityNotification) {
        const { captcha, ...payload } = availabilityNotification;
        const url = `${this.apiBaseUrl}/availability-notifications`;
        const headers = { 'X-Captcha': captcha };
        const response = await axios.post(url, payload, { headers });

        return {
            ok: response.status === 201,
            data: response.data
        };
    }
}