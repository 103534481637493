import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../../containers/AppContainer';
import Hidden from '@material-ui/core/Hidden';
import VariantTable from './VariantTable';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ImageGrid from '../../components/ImageGrid';
import StockStatus from '../../components/StockStatus';
import Lightbox from 'react-images';
import Button from '@material-ui/core/Button';
import { toHtmlString } from '../../helpers';
import classNames from 'classnames';
import { useWidth } from '../../hooks';
import { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';
import { paragraphsStyle } from '../../styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    images: {
        flex: 1,
        marginLeft: theme.spacing(1),
        maxHeight: `${200 + theme.spacing(1)}px`,
        overflowY: 'auto'
    },
    hero: {
        alignSelf: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        width: `${200 + theme.spacing(1)}px`,
        height: `${200 + theme.spacing(1)}px`,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2)
        }
    },
    imageModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        },
        '& > img': {
            outline: 'none',
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    block: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'stretch'
        }
    },
    gutterBottom: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    gutterTop: {
        marginTop: theme.spacing(2)
    },
    ...paragraphsStyle(theme)
}));

function appendImages(images, productOrVariant) {
    if (Boolean(productOrVariant.heroImageUrl)) {
        images.push({
            imageUrl: productOrVariant.heroImageUrl,
            description: productOrVariant.name || productOrVariant.attributeValuesDescription,
            isFeatured: productOrVariant.isFeatured
        });
    }

    images.push(...productOrVariant.images);
}

function ProductDetails({ store, product, onOpenStore }) {
    const classes = useStyles();
    const width = useWidth();
    const [imageIndex, setImageIndex] = useState(0);
    const [isImageOpen, setIsImageOpen] = useState(false);

    const onOpenImage = (ac, index) => {
        setImageIndex(index);
        setIsImageOpen(true);

        ac.onStoreGalleryViewed(product.id);
    }

    const onCloseImage = _ => setIsImageOpen(false);

    const allImages = [];

    appendImages(allImages, product);

    for (const variant of product.variants) {
        appendImages(allImages, variant);
    }

    // Convert product images into lightbox format.
    const lightboxImages = allImages.map(img => ({
        src: img.imageUrl,
        caption: img.description
    }));

    let imageGridCols = 6;

    for (const breakpoint of ['lg', 'md', 'sm', 'xs']) {
        if (isWidthDown(breakpoint, width)) {
            imageGridCols--;
        }
    }

    return <div className={classes.root}>

        <Subscribe to={[AppContainer]}>
            {ac => <Fragment>

                <Hidden xsDown>
                    <div className={classes.header}>
                        <div className={classes.hero}
                            style={{ backgroundImage: `url("${product.heroImageUrl || '/images/no-image.png'}")` }}
                            title={`${product.name}`}
                            onClick={() => onOpenImage(ac, 0)} />

                        <div className={classes.images}>
                            <ImageGrid images={allImages.slice(1)}
                                cellHeight={100}
                                cols={imageGridCols}
                                spacing={8}
                                wrap={true}
                                onSelect={(img, idx) => onOpenImage(ac, idx + 1)} />
                        </div>
                    </div>
                </Hidden>

                <Hidden smUp>
                    <div className={classes.hero}
                        style={{ backgroundImage: `url("${product.heroImageUrl || '/images/no-image.png'}")` }}
                        title={`${product.name}`}
                        onClick={() => onOpenImage(ac, 0)} />

                    <Divider className={classes.gutterBottom} />

                    {product.images.length > 0 && <div className={classes.gutterBottom}>
                        <ImageGrid images={allImages.slice(1)}
                            cellHeight={100}
                            cols={2.5}
                            onSelect={(img, idx) => onOpenImage(ac, idx + 1)} />
                    </div>}
                </Hidden>

                {/* <div className={classes.gutterBottom}>
                    <StoreContactActions store={store}
                        onPhoneClick={() => ac.onStorePhoneClicked(store.id)}
                        onWebsiteClick={() => ac.onStoreWebsiteClicked(store.id)}
                        onMapClick={() => ac.onStoreMapClicked(store.id)}
                        onDirectionsClick={() => ac.onStoreDirectionsClicked(store.id)} />
                </div> */}
            </Fragment>}
        </Subscribe>

        <Lightbox images={lightboxImages}
            currentImage={imageIndex}
            isOpen={isImageOpen}
            backdropClosesModal={true}
            showImageCount={false}
            onClickNext={() => setImageIndex(imageIndex + 1)}
            onClickPrev={() => setImageIndex(imageIndex - 1)}
            onClose={onCloseImage} />

        <Divider className={classes.gutterBottom} />
        {Boolean(product.description) && <div className={classNames(classes.paragraphs, classes.gutterBottom)}>
            <Typography variant="overline">About</Typography>
            {toHtmlString(product.description)}
        </div>}
        {Boolean(product.brand) && <div className={classes.gutterBottom}>
            <Typography variant="overline">Brand</Typography>
            <Typography>{product.brand}</Typography>
        </div>}
        {product.variants.length === 0 && Boolean(product.sku) && <div className={classes.gutterBottom}>
            <Typography variant="overline">SKU</Typography>
            <Typography>{product.sku}</Typography>
        </div>}
        {product.variants.length === 0 && <div className={classes.gutterBottom}>
            <Typography variant="overline">Stock</Typography>
            <StockStatus stockStatus={product.stockStatus} />
        </div>}
        {product.variants.length > 0 && <div className={classes.gutterBottom}>
            <Typography variant="overline">Variants</Typography>
            <div style={{ overflowX: 'auto' }}>
                <VariantTable size="small" attributes={product.attributes} variants={product.variants} />
            </div>
        </div>}
        <div className={classNames(classes.block, classes.gutterBottom)}>
            <Typography variant="overline">Sold by</Typography>
            <Typography>{store.name}</Typography>
            <Button className={classes.button}
                component="a"
                href={`${window.location.origin}/stores/${store.storeSlug}`}
                color="primary"
                size="small"
                onClick={e => {
                    e.preventDefault();
                    onOpenStore(store.storeSlug);
                }}>
                See more about this store
            </Button>
        </div>
        {/* TODO: Similar products???
            {product.similarProducts.length > 0 && <div className={classes.gutterBottom}>
                <Typography variant="overline">Similar products</Typography>
                <Grid container spacing={2}>
                    {product.similarProducts.map(similarProduct => <Grid key={similarProduct.id} item xs={12} sm={6} lg={4}>
                        <SimilarProductCard store={similarProduct} onSelect={() => onOpenSimilarProduct(similarProduct.id, similarProduct.slug)} />
                    </Grid>)}
                </Grid>
            </div>} */}
    </div>;
}

ProductDetails.propTypes = {
    store: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    onOpenStore: PropTypes.func.isRequired
};

export default ProductDetails;