import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import BusyButton from '../../components/BusyButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { readErrors } from '../../helpers';
import { actionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    },
    ...actionsStyle(theme)
});

class PersonalDetailsForm extends Component {
    static propTypes = {
        firstName: PropTypes.string,
        canEditFirstName: PropTypes.bool.isRequired,
        lastName: PropTypes.string,
        canEditLastName: PropTypes.bool.isRequired,
        emailAddress: PropTypes.string,
        canEditEmailAddress: PropTypes.bool.isRequired,
        phoneNumber: PropTypes.string,
        canEditPhoneNumber: PropTypes.bool.isRequired,
        suburb: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            errors: {},
            busy: false
        }
    }

    // We don't want to send the locality field on the update request unless it's actually changed.
    hasLocalityChanged = false;

    onChange = name => event => {
        this.props.onChange({
            [name]: event.target.value,
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { firstName, lastName, emailAddress, phoneNumber, locality } = this.props;

        try {
            this.setState({ busy: true });

            const data = {
                firstName,
                lastName,
                emailAddress,
                phoneNumber
            };

            if (this.hasLocalityChanged) {
                data.locality = locality;
            }

            await onSave(data);

            this.setState({ errors: {} });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({ errors });
            } else {
                throw error;
            }
        }
        finally {
            this.setState({ busy: false });
        }
    }

    render() {
        const {
            classes,
            width,
            firstName,
            canEditFirstName,
            lastName,
            canEditLastName,
            emailAddress,
            canEditEmailAddress,
            phoneNumber,
            canEditPhoneNumber,
            locality
        } = this.props;

        const { errors, busy } = this.state;
        const isMobile = isWidthDown('xs', width);
        const canEditAll = canEditFirstName && canEditLastName && canEditEmailAddress && canEditPhoneNumber;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <Grid container spacing={isMobile ? 0 : 1}>
                {!canEditAll &&
                    <Grid item xs={12}>
                        <Typography className={classes.banner} variant="caption">
                            <InfoIcon style={{ marginRight: 8 }} />
                            Some of your details aren't stored by us and cannot be updated here.
                        </Typography>
                    </Grid>}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="firstName"
                        label="First name"
                        required
                        fullWidth
                        autoFocus
                        disabled={!canEditFirstName}
                        margin="normal"
                        value={firstName || ''}
                        helperText={errors.firstName}
                        error={Boolean(errors.firstName)}
                        onChange={this.onChange('firstName')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lastName"
                        label="Last name"
                        required
                        fullWidth
                        disabled={!canEditLastName}
                        margin="normal"
                        value={lastName || ''}
                        helperText={errors.lastName}
                        error={Boolean(errors.lastName)}
                        onChange={this.onChange('lastName')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="emailAddress"
                        label="Email address"
                        required
                        fullWidth
                        disabled={!canEditEmailAddress}
                        margin="normal"
                        type="email"
                        value={emailAddress || ''}
                        helperText={errors.emailAddress}
                        error={Boolean(errors.emailAddress)}
                        onChange={this.onChange('emailAddress')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="phoneNumber"
                        label="Phone number"
                        fullWidth
                        disabled={!canEditPhoneNumber}
                        margin="normal"
                        type="tel"
                        value={phoneNumber || ''}
                        helperText={errors.phoneNumber}
                        error={Boolean(errors.phoneNumber)}
                        onChange={this.onChange('phoneNumber')} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={6}>
                    <AddressAutocomplete
                        types={['(cities)']}
                        label="Locality"
                        fullWidth
                        margin="normal"
                        value={(Boolean(locality) ? locality.formattedAddress : '') || ''}
                        helperText={errors.phoneNumber || 'Providing your locality helps us tailor your search experience'}
                        error={Boolean(errors.locality)}
                        onChange={locality => {
                            if (locality == null) {
                                this.props.onChange({ locality: null });
                            } else {
                                const { country, countryCode, formattedAddress, googlePlaceId, latitude, longitude, postcode, state, stateName, suburb } = locality;

                                this.props.onChange({
                                    locality: {
                                        country,
                                        countryCode,
                                        formattedAddress,
                                        googlePlaceId,
                                        latitude,
                                        longitude,
                                        postcode,
                                        state,
                                        stateName,
                                        suburb
                                    }
                                });
                            }

                            this.hasLocalityChanged = true;
                        }} /></Grid>
            </Grid>

            <div className={classes.actions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
            </div>
        </form>;
    }
}

export default withStyles(styles)(withWidth()(PersonalDetailsForm));