import ApiService from './ApiService';
import axios from 'axios';

export default class AnalyticsEventService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    // Store events.
    storePhoneClicked = storeId => this.postEvent('910467E1-FCDC-4A78-8776-8D2F7C9631B3', { storeId });
    storeWebsiteClicked = storeId => this.postEvent('DE8837F4-A0F3-4768-9186-0F7C342F78CB', { storeId });
    storeMapClicked = storeId => this.postEvent('3D55C9DD-F92D-460C-8D10-95E2841F1133', { storeId });
    storeDirectionsClicked = storeId => this.postEvent('8668E200-0E6B-4EE1-A2AF-1B147732856F', { storeId });
    storeGalleryViewed = storeId => this.postEvent('9C725D4C-BB2E-405B-ABEB-58C079F81FEF', { storeId });
    nearbyStoreClicked = (originStoreId, nearbyStoreId) => this.postEvent('66145C4F-06A9-4146-8EAD-83AC11A6F48E', { originStoreId, nearbyStoreId });

    // Shopping location events.
    shoppingLocationPhoneClicked = shoppingLocationId => this.postEvent('39FA8BF9-DCA5-46C7-8AD7-CB4B929E3107', { shoppingLocationId });
    shoppingLocationWebsiteClicked = shoppingLocationId => this.postEvent('9DC13D9D-533E-49D5-BC7F-E0F073FA24A7', { shoppingLocationId });
    shoppingLocationMapClicked = shoppingLocationId => this.postEvent('86D7F0B7-D66D-4F10-839B-2573195B8210', { shoppingLocationId });
    shoppingLocationDirectionsClicked = shoppingLocationId => this.postEvent('B50B1950-9A81-496F-992C-E668AC711E84', { shoppingLocationId });
    shoppingLocationGalleryViewed = shoppingLocationId => this.postEvent('52DB55C0-51D9-4B99-8CB9-96065C4BED5F', { shoppingLocationId });

    // Share events.
    shareSearchClicked = (utms) => this.postEvent('F00227CB-E477-418E-8E99-8072CA1EA9FC', { ...utms });
    shareStoreClicked = (storeId, utms) => this.postEvent('E0BBD9F8-967C-4B43-9F6C-FA08904FABF2', { storeId, ...utms });
    shareProductClicked = (productId, utms) => this.postEvent('40CE53BB-9E6D-4762-8463-81510AB559C3', { productId, ...utms });
    shareShoppingLocationClicked = (shoppingLocationId, utms) => this.postEvent('FFC476E3-3645-4A59-B60F-234B6F4C6D61', { shoppingLocationId, ...utms });

    userLocationChanged = () => this.postEvent('A6989400-E6DD-4FA8-ACFC-E9587889BB3F', null);

    async postEvent(path, data) {
        const headers = this.getRequestHeaders(this.getAccessToken());
        const url = `${this.apiBaseUrl}/events/${path}`;
        const response = await axios.post(url, data, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }
}