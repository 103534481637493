import { Container } from 'unstated';

const defaultOptions = {
    orderBy: 'relevance',
    radius: 50,
    pageNumber: 1,
    pageSize: 20,
    imageSearch: false,
    trending: false,
    test: false
};

export default class StoreSearchContainer extends Container {
    constructor(storeSearchService, customerService, options) {
        super();

        this.storeSearchService = storeSearchService;
        this.customerService = customerService;

        const actualOptions = {
            ...defaultOptions,
            ...options
        };

        this.state = {
            ...actualOptions,
            // Inputs
            query: null,
            brands: [],
            categories: [],
            features: [],
            locations: [],
            tags: [],
            coords: null,
            // Outputs
            stores: [],
            facets: null,
            totalCount: 0,
            expandedStores: []
        };
    }

    hasLocation = () => Boolean(this.state.coords);

    hasMore() {
        const { stores, totalCount } = this.state;
        return stores.length < totalCount;
    }

    async load() {
        const { pageSize } = this.state;
        await this.loadPage(1, pageSize);
    }

    async loadPage(pageNumber, pageSize) {
        const { query, brands, categories, features, locations, tags, orderBy, coords, radius, imageSearch, trending, test } = this.state;
        const hasCoords = this.hasLocation();

        const response = await this.storeSearchService.search(query,
            brands,
            categories,
            features,
            locations,
            tags,
            orderBy,
            hasCoords ? coords.longitude : null,
            hasCoords ? coords.latitude : null,
            hasCoords ? radius : null,
            pageNumber,
            pageSize,
            imageSearch,
            trending,
            test);

        const { stores, facets, totalCount, expandedStores } = response;

        this.setState({ stores, facets, pageNumber, pageSize, totalCount, expandedStores });
    }

    async loadMore() {
        const { query, brands, categories, features, locations, tags, orderBy, coords, radius, pageNumber, pageSize, imageSearch, trending, test } = this.state;
        const hasCoords = this.hasLocation();

        const response = await this.storeSearchService.search(query,
            brands,
            categories,
            features,
            locations,
            tags,
            orderBy,
            hasCoords ? coords.longitude : null,
            hasCoords ? coords.latitude : null,
            hasCoords ? radius : null,
            pageNumber + 1,
            pageSize,
            imageSearch,
            trending,
            test);

        const { stores, facets } = response;

        this.setState(prevState => ({
            stores: prevState.stores.concat(stores),
            facets,
            pageNumber: pageNumber + 1
        }));
    }

    // clear() {
    //     this.setState({
    //         query: null,
    //         brands: [],
    //         categories: [],
    //         features: [],
    //         locations: [],
    //         tags: [],
    //         orderBy: null,
    //         stores: [],
    //         facets: null,
    //         pageNumber: 1,
    //         totalCount: 0,
    //         expandedStores: [],
    //         imageSearch: false,
    //         trending: false
    //     });
    // }

    async createSavedSearch(customerId, savedSearch) {
        const { query, brands, categories, features, locations, tags, coords, radius, orderBy } = this.state;

        const values = {
            query,
            brands,
            categories,
            features,
            locations,
            tags,
            orderBy
        };

        if (savedSearch.saveLocation && this.hasLocation()) {
            values.latitude = coords.latitude;
            values.longitude = coords.longitude;

            if (Boolean(radius)) {
                values.radius = radius;
            }
        }

        const { name, description } = savedSearch;

        const payload = {
            name,
            description,
            values
        };

        const response = await this.customerService.createSavedSearch(customerId, payload);

        return response.ok;
    }

    async createAvailabilityNotification(availabilityNotification) {
        const { query, brands, categories, features, locations, tags, coords, radius, orderBy } = this.state;

        const values = {
            query,
            brands,
            categories,
            features,
            locations,
            tags,
            orderBy
        };

        if (this.hasLocation()) {
            values.latitude = coords.latitude;
            values.longitude = coords.longitude;

            if (Boolean(radius)) {
                values.radius = radius;
            }
        }

        const { emailAddress, captcha } = availabilityNotification;

        const payload = {
            emailAddress,
            captcha,
            values
        };

        const response = await this.storeSearchService.createAvailabilityNotification(payload);

        return response.ok;
    }
}