import { Container } from 'unstated';
import storage from 'local-storage-fallback';
import base64url from 'base64url';
import axios from 'axios';

const GET_POSITION_TIMEOUT = 10 * 1000;
const GET_POSITION_INTERVAL = 15 * 1000;

export default class AppContainer extends Container {
    geoWatchId = null;

    constructor(config, analyticsEventService) {
        super();

        this.config = config;

        axios.interceptors.request.use(config => {
            const { devicePosition } = this.state;

            // Send device position with every request (if we have it).
            if (devicePosition != null) {
                config.headers['X-Latitude'] = devicePosition.latitude;
                config.headers['X-Longitude'] = devicePosition.longitude;
            }

            return config;
        });

        this.analyticsEventService = analyticsEventService;
        this.onLocationNameResolved = this.onLocationNameResolved.bind(this);
        this.shortenUrl = this.shortenUrl.bind(this);
    }

    state = {
        accessToken: storage.getItem('AccessToken'),
        refreshToken: storage.getItem('RefreshToken'),
        devicePosition: null,
        devicePositionUnavailable: false,
        deviceLocationName: null,
        deviceLocationNameOpen: true
    };

    hasToken = () => Boolean(this.state.accessToken);

    getAccessToken() { return this.state.accessToken; }

    getClaim(name, defaultValue = null) {
        const accessToken = this.getAccessToken();

        if (!Boolean(accessToken)) {
            // We don't have any stored tokens.
            return defaultValue || null;
        }

        try {
            const claims = JSON.parse(base64url.decode(accessToken.split('.')[1]));

            // return the value of the claim, or undefined if the claim does not exist
            return claims[name] || defaultValue;
        } catch {
            console.log('Failed to decode access token. Forcible sign out will now occur.');
            this.signOut();
        }
    }

    signIn = (accessToken, refreshToken) => {
        storage.setItem('AccessToken', accessToken);
        storage.setItem('RefreshToken', refreshToken);

        return this.setState({ accessToken, refreshToken });
    };

    signOut = () => {
        storage.removeItem('AccessToken');
        storage.removeItem('RefreshToken');

        return this.setState({ accessToken: null, refreshToken: null });
    };

    isTrackingPosition() {
        return Boolean(this.geoWatchId);
    }

    startTrackingPosition() {
        if (this.isTrackingPosition()) {
            return;
        }

        if (navigator.geolocation) {
            const geoOptions = {
                enableHighAccuracy: false,
                timeout: GET_POSITION_TIMEOUT,
                maximumAge: 0
            };

            const getPosition = () => navigator.geolocation.getCurrentPosition(
                pos => this.onPositionChanged(pos.coords),
                _ => this.onPositionChanged(null),
                geoOptions);

            getPosition();

            this.geoWatchId = setInterval(getPosition, GET_POSITION_INTERVAL);
        }
    }

    stopTrackingPosition() {
        if (!this.isTrackingPosition()) {
            return;
        }

        clearInterval(this.geoWatchId);
        this.geoWatchId = null;

        this.setState({
            devicePosition: null,
            deviceLocationName: null
        });
    }

    async onPositionChanged(devicePosition) {
        if (Boolean(devicePosition)) {
            const { devicePosition: lastPosition } = this.state;

            if (lastPosition != null &&
                devicePosition.latitude === lastPosition.latitude &&
                devicePosition.longitude === lastPosition.longitude) {
                // Ignore duplicate events for same position.
                return;
            }

            const { latitude, longitude } = devicePosition;

            await this.setState({
                devicePosition: {
                    latitude,
                    longitude
                },
                devicePositionUnavailable: false
            });

            // Record analytics event. No need to await this.
            this.analyticsEventService.userLocationChanged();

            // Reverse geocode coords to obtain search location name.
            resolveLocationName(latitude, longitude, this.onLocationNameResolved);
        } else {
            this.setState({
                devicePosition: null,
                devicePositionUnavailable: true,
                deviceLocationName: null
            });
        }
    }

    onLocationNameResolved(results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
            const addressComponents = getAddressComponents(results[0]);
            const { suburb, state, country } = addressComponents;

            const deviceLocationName = [suburb, state, country]
                .filter(s => Boolean(s))
                .join(', ');

            if (deviceLocationName !== this.state.deviceLocationName) {
                this.setState({ deviceLocationName, deviceLocationNameOpen: true });

                // Hide the location popper after a few seconds.
                clearTimeout(this.locationNameTimeout);
                this.locationNameTimeout = setTimeout(() => this.setState({ deviceLocationNameOpen: false }), 3000);
            }
        }
    }

    async shortenUrl(url) {
        const headers = { 'Content-Type': 'application/json' };

        const response = await axios
            .post(`${this.config.urlShortenerUrl}/from`,
                { url },
                { headers });

        return response.status === 201 ? response.data : url;
    }

    // Store events.
    onStorePhoneClicked = storeId => this.analyticsEventService.storePhoneClicked(storeId);
    onStoreWebsiteClicked = storeId => this.analyticsEventService.storeWebsiteClicked(storeId);
    onStoreMapClicked = storeId => this.analyticsEventService.storeMapClicked(storeId);
    onStoreDirectionsClicked = storeId => this.analyticsEventService.storeDirectionsClicked(storeId);
    onStoreGalleryViewed = storeId => this.analyticsEventService.storeGalleryViewed(storeId);
    onNearbyStoreClicked = (originStoreId, nearbyStoreId) => this.analyticsEventService.nearbyStoreClicked(originStoreId, nearbyStoreId);

    // Shopping location events.
    onShoppingLocationPhoneClicked = shoppingLocationId => this.analyticsEventService.shoppingLocationPhoneClicked(shoppingLocationId);
    onShoppingLocationWebsiteClicked = shoppingLocationId => this.analyticsEventService.shoppingLocationWebsiteClicked(shoppingLocationId);
    onShoppingLocationMapClicked = shoppingLocationId => this.analyticsEventService.shoppingLocationMapClicked(shoppingLocationId);
    onShoppingLocationDirectionsClicked = shoppingLocationId => this.analyticsEventService.shoppingLocationDirectionsClicked(shoppingLocationId);
    onShoppingLocationGalleryViewed = shoppingLocationId => this.analyticsEventService.shoppingLocationGalleryViewed(shoppingLocationId);

    // Share events.
    onShareSearchClicked = (utms) => this.analyticsEventService.shareSearchClicked(utms);
    onShareStoreClicked = (storeId, utms) => this.analyticsEventService.shareStoreClicked(storeId, utms);
    onShareProductClicked = (productId, utms) => this.analyticsEventService.shareProductClicked(productId, utms);
    onShareShoppingLocationClicked = (shoppingLocationId, utms) => this.analyticsEventService.shareShoppingLocationClicked(shoppingLocationId, utms);
}

export function resolveLocationName(lat, lng, onComplete) {
    var geocoder = new window.google.maps.Geocoder();

    var geocodeRequest = {
        location: { lat, lng }
    };

    geocoder.geocode(geocodeRequest, onComplete);
}

export function getAddressComponents(result) {
    var components = (result.address_components || [])
        .reduce((acc, data) => {
            data.types.forEach(type => acc[type] = data);
            return acc;
        }, {});

    const get = (name, short = false) => {
        if (!(name in components)) return '';
        return short ? components[name].short_name : components[name].long_name;
    };

    return {
        unitNumber: get('subpremise'),
        streetNumber: get('street_number'),
        streetName: get('route'),
        suburb: get('locality') ||
            get('sublocality') ||
            get('sublocality_level_1') ||
            get('neighborhood') ||
            get('administrative_area_level_3'),
        //city: get('administrative_area_level_2', true),
        state: get('administrative_area_level_1', true),
        stateName: get('administrative_area_level_1'),
        country: get('country'),
        countryCode: get('country', true),
        postcode: get('postal_code')
    };
}