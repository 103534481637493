import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../../containers/AppContainer';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../../components/Footer';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import TwitterLogin from 'react-twitter-auth';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router';
import { spinnerStyle } from '../../styles';

const styles = theme => ({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.6rem'
        }
    },
    providers: {
        display: 'flex',
        justifyContent: 'center',
        flex: '0, 0',
        margin: theme.spacing(2),
    },
    card: {
        margin: theme.spacing(2),
        minWidth: '50px',
        maxWidth: '300px',
        width: '25%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1)
        }
    },
    cardContent: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    },
    logo: {
        maxWidth: '100%'
    },
    backdrop: {
        zIndex: 'auto'
    },
    ...spinnerStyle(theme)
});

class SignIn extends Component {
    static propTypes = {
        apiBaseUrl: PropTypes.string.isRequired,
        authService: PropTypes.object.isRequired,
        facebookAppId: PropTypes.string.isRequired,
        googleClientId: PropTypes.string.isRequired
    };

    state = {
        error: null,
        busy: false
    };

    async onSignInSuccess(app, token, provider) {
        const { authService } = this.props;
        const response = await authService.signInExternal(token, provider);

        if (response.status === 200) {
            const { accessToken, refreshToken } = response.data;
            const { history, location } = this.props;
            const { state } = location;
            const from = state != null ? state.from : '/';

            app.signIn(accessToken, refreshToken).then(() => history.push(from));
        } else {
            this.onSignInFailure();
        }
    }

    async handleTwitterSignInResponse(app, response) {
        if (response.status === 200) {
            const json = await response.json();
            const { accessToken, refreshToken } = json;
            const { history, location } = this.props;
            const { state } = location;
            const from = state != null ? state.from : '/';

            app.signIn(accessToken, refreshToken).then(() => history.push(from));
        } else {
            this.onSignInFailure();
        }
    }

    onSignInFailure() {
        this.setState({
            error: 'There was a problem signing you in. Please try again.',
            busy: false
        });
    }

    onBeginSignIn(p, e) {
        this.setState({ busy: true });
        p.onClick(e);
    }

    render() {
        const { classes, apiBaseUrl, facebookAppId, googleClientId } = this.props;
        const { busy } = this.state;

        const renderFacebookSignIn = app => <FacebookLogin appId={facebookAppId}
            disableMobileRedirect
            callback={e => this.onSignInSuccess(app, e.accessToken, 'facebook')}
            onFailure={() => this.onSignInFailure()}
            render={p =>
                <Card className={classes.card}>
                    <CardActionArea onClick={e => this.onBeginSignIn(p, e)}>
                        <CardContent classes={{ root: classes.cardContent }}>
                            <img src="/images/facebook.png" alt="Facebook logo" title="Facebook logo" className={classes.logo} />
                        </CardContent>
                    </CardActionArea>
                </Card>} />;

        const renderGoogleSignIn = app => <GoogleLogin clientId={googleClientId}
            onSuccess={e => this.onSignInSuccess(app, e.tokenId, 'google')}
            onFailure={() => this.onSignInFailure()}
            render={p =>
                <Card className={classes.card}>
                    <CardActionArea onClick={e => this.onBeginSignIn(p, e)}>
                        <CardContent classes={{ root: classes.cardContent }}>
                            <img src="/images/google.png" alt="Google logo" title="Google logo" className={classes.logo} />
                        </CardContent>
                    </CardActionArea>
                </Card>} />;

        const renderTwitterSignIn = app => <Card className={classes.card}>
            <CardActionArea onClick={() => this.setState({ busy: true })}>
                <CardContent classes={{ root: classes.cardContent }}>
                    <TwitterLogin tag="div"
                        requestTokenUrl={`${apiBaseUrl}/auth/twitter/initiate`}
                        loginUrl={`${apiBaseUrl}/auth/twitter`}
                        onSuccess={response => this.handleTwitterSignInResponse(app, response)}
                        onFailure={() => this.onSignInFailure()}
                        showIcon={false}>
                        <img src="/images/twitter.png" alt="Twitter logo" title="Twitter logo" className={classes.logo} />
                    </TwitterLogin>
                </CardContent>
            </CardActionArea>
        </Card>;

        return <div className={classes.root}>
            <Typography align="center" display="block" variant="h2" className={classes.title}>How do you want to sign in?</Typography>
            <div className={classes.providers}>
                <Subscribe to={[AppContainer]}>
                    {app => <Fragment>
                        {renderFacebookSignIn(app)}
                        {renderGoogleSignIn(app)}
                        {renderTwitterSignIn(app)}
                    </Fragment>}
                </Subscribe>
            </div>
            {busy && <Fragment><Backdrop open={true} classes={{ root: classes.backdrop }} />
                <CircularProgress className={classes.spinner} size={90} thickness={2.4} />
            </Fragment>}
            <Footer />
        </div>;
    }
}

export default withRouter(withStyles(styles)(SignIn));