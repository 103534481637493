import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from './components/Page';
import { Provider } from 'unstated';
import AppContainer from './containers/AppContainer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Route, Redirect } from 'react-router';
import { createBrowserHistory } from 'history';
import { Switch } from 'react-router';
import SecureRoute from './components/SecureRoute';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import MyProfile from './pages/MyProfile';
import Search from './pages/Search';
import AuthService from './services/AuthService';
import StoreSearchService from './services/StoreSearchService';
import ProductSearchService from './services/ProductSearchService';
import BlobService from './services/BlobService';
import ImageSearchService from './services/ImageSearchService';
import queryString from 'query-string';
import CustomerService from './services/CustomerService';
import AnalyticsEventService from './services/AnalyticsEventService';
import ShoppingLocation from './pages/ShoppingLocation';
import Store from './pages/Store';
import Product from './pages/Product';
import NotFound from './pages/Statuses/NotFound';
import ReactGA from 'react-ga';
import axios from 'axios';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#673ab7'
    },
    secondary: {
      main: '#424242'
    }
  }
});

const history = createBrowserHistory();

export default class App extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.appContainer = new AppContainer(props.config, new AnalyticsEventService(props.config.apiBaseUrl, () => this.appContainer.getAccessToken()));
    this.authService = new AuthService(props.config.apiBaseUrl);

    if (this.isRetailer()) {
      // Redirect retailer to their portal.
      window.location = this.props.config.retailerPortalUrl;
      return;
    }

    if (props.config.googleAnalyticsTrackingCode) {
      ReactGA.initialize(props.config.googleAnalyticsTrackingCode);
    }

    // Response error interceptor.
    axios.interceptors.response.use(null, error => this.handleErrorResponse(error));

    this.blobService = new BlobService(props.config.apiBaseUrl,
      () => this.appContainer.getAccessToken(),
      props.config.blobStorageBaseUrl);

    this.customerService = new CustomerService(props.config.apiBaseUrl,
      () => this.appContainer.getAccessToken());

    this.imageSearchService = new ImageSearchService(props.config.apiBaseUrl,
      () => this.appContainer.getAccessToken());

    this.productSearchService = new ProductSearchService(props.config.apiBaseUrl,
      () => this.appContainer.getAccessToken());

    this.storeSearchService = new StoreSearchService(props.config.apiBaseUrl,
      () => this.appContainer.getAccessToken(),
      () => this.appContainer.state.devicePosition);

    history.listen(() => {
      if (this.isRetailer()) {
        window.location = props.config.retailerPortalUrl;
      }
    });
  }

  componentDidMount() {
    // Default location tracking to on.
    this.appContainer.startTrackingPosition();
  }

  handleErrorResponse(error) {
    const { response } = error;
    const { status } = response;

    switch (status) {
      case 401:
        return this.handleUnauthorizedResponse(response);
      case 429:
        return this.handleTooManyRequestsResponse(response);
      default:
        return Promise.reject(error);
    }
  }

  async handleUnauthorizedResponse(response) {
    const { config, headers } = response;
    const { apiBaseUrl } = this.props.config;

    const tokenNeedsRefreshing = headers['token-expired'] &&
      !config._isRetry && // Not a retry request.
      config.url !== apiBaseUrl + '/auth/refresh'; // Not a refresh request.

    if (tokenNeedsRefreshing) {
      let resp;

      if (this.refresh) {
        // Refresh request already in flight. Wait until it's done then we'll retry this request.
        resp = await this.refresh;
      } else {
        const { accessToken, refreshToken } = this.appContainer.state;

        try {
          this.refresh = this.authService.refreshToken(accessToken, refreshToken);
          resp = await this.refresh;
        } catch {
          // Failed to refresh token. Force sign out.
          config.headers.Authorization = null;
          await this.appContainer.signOut();
          return axios(config);
        } finally {
          this.refresh = null;
        }

        await this.appContainer.signIn(resp.data.accessToken, resp.data.refreshToken);
      }

      // Retry original request with new access token.
      config._isRetry = true;
      config.headers.Authorization = `Bearer ${resp.data.accessToken}`;

      return axios(config);
    } else {
      // Token couldn't be refreshed.
      await this.appContainer.signOut();
      
      // Allow pending state transitions to settle then redirect to sign in.
      setTimeout(() => history.push({
        pathname: '/sign-in',
        state: {
          from: history.location
        }
      }));
    }
  }

  handleTooManyRequestsResponse(response) {
    const { config } = response;

    config._isRetry = true;

    // Begin retry attempts with exponential backoff.
    if (!config._wait) {
      config._retryAttempt = 1;
      config._wait = 1000;
    } else {
      config._retryAttempt++;
      config._wait *= 2;
    }

    return new Promise(res => setTimeout(() => res(axios(config)), config._wait));
  }

  renderSearch(config, props) {
    const { location } = props;

    if (!Boolean(location.search)) {
      // Empty queries should always direct user back to home page.
      return <Redirect to="/" />;
    }

    // Note: Unique key used to re-mount Search component every time the search query changes.
    return <Page title="Matching Stores">
      <Search
        key={new Date().getTime()}
        params={queryString.parse(location.search)}
        imageSearch={location.state && location.state.imageSearch}
        trending={location.state && location.state.trending}
        storeSearchService={this.storeSearchService}
        productSearchService={this.productSearchService}
        customerService={this.customerService}
        googleApiKey={config.googleApiKey} />
    </Page>;
  }

  isAdmin() { return Boolean(this.appContainer.getClaim('admin', false)); }

  isRetailer() { return Boolean(this.appContainer.getClaim('retailer_id', false)); }

  isCustomer() { return Boolean(this.appContainer.getClaim('customer', false)); }

  render() {
    const { config } = this.props;
    const { googleAnalyticsTrackingCode } = config;

    const routes = <Switch>
      <Route exact
        path="/"
        render={() => <Page>
          <Home storeSearchService={this.storeSearchService}
            imageSearchService={this.imageSearchService}
            blobService={this.blobService} />
        </Page>} />

      <Route exact
        path="/sign-in"
        render={() => <Page title="Sign In">
          <SignIn apiBaseUrl={config.apiBaseUrl}
            authService={this.authService}
            facebookAppId={config.facebookAppId}
            googleClientId={config.googleClientId} />
        </Page>} />

      <Route exact
        path="/sign-in/go"
        render={() => "Please wait..."} />

      <SecureRoute exact
        path="/my-profile/:tab?"
        authorised={() => this.appContainer.hasToken() && !this.isAdmin()}
        render={props => <Page title="My Profile">
          <MyProfile
            customerId={this.appContainer.getClaim('sub')}
            customerService={new CustomerService(config.apiBaseUrl, () => this.appContainer.getAccessToken())}
            storeSearchService={this.storeSearchService}
            tab={props.match.params.tab} />
        </Page>} />

      <Route exact
        path="/search"
        render={props => this.renderSearch(config, props)} />

      <Route exact
        path="/shopping-locations/:slug"
        render={props => <ShoppingLocation key={props.match.params.slug}
          slug={props.match.params.slug}
          storeSearchService={this.storeSearchService}
          googleApiKey={config.googleApiKey} />} />

      <Route exact
        path="/stores/:slug"
        render={props => <Store key={props.match.params.slug}
          slug={props.match.params.slug}
          params={queryString.parse(props.location.search)}
          storeSearchService={this.storeSearchService}
          productSearchService={this.productSearchService}
          googleApiKey={config.googleApiKey} />} />

      <Route exact
        path="/stores/:storeSlug/products/:productSlug"
        render={props => <Product key={props.match.params.productSlug}
          storeSlug={props.match.params.storeSlug}
          productSlug={props.match.params.productSlug}
          storeSearchService={this.storeSearchService}
          productSearchService={this.productSearchService} />} />

      <Route render={() => <Page title="Not Found"><NotFound /></Page>} />
    </Switch>;

    // Capture page views in GA if enabled.
    const capturePageView = <Route path="/" render={({ location }) => {
      ReactGA.pageview(location.pathname + location.search);
      return null;
    }} />;

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GoogleReCaptchaProvider reCaptchaKey={config.googleReCaptchaSiteKey}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Provider inject={[this.appContainer]}>
            <Router history={history}>
              {googleAnalyticsTrackingCode && capturePageView}
              {routes}
            </Router>
          </Provider>
        </MuiThemeProvider>
      </GoogleReCaptchaProvider>
    </MuiPickersUtilsProvider>;
  }
}