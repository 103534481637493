import React from 'react';
import PropTypes from 'prop-types';
import parseUrl from 'url-parse';
import { generateUid } from '../helpers';
import { makeStyles } from '@material-ui/styles';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon
} from './react-share';

// UTM campaign.
const SHARE_CAMPAIGN = 'share';

// UTM mediums.
const SOCIAL_MEDIUM = 'social';
const EMAIL_MEDIUM = 'email';

// UTM sources.
const FACEBOOK_SOURCE = 'facebook';
const TWITTER_SOURCE = 'twitter';
const LINKEDIN_SOURCE = 'linkedin';
const EMAIL_SOURCE = 'email';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: props => props.vertical ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        cursor: 'pointer',
        '&:not(:last-child)': {
            marginRight: props => props.vertical ? 0 : theme.spacing(1),
            marginBottom: props => props.vertical ? theme.spacing(1) : 0
        }
    }
}));

function ShareButtons(props) {
    const classes = useStyles(props);
    const { url: originalUrl, size, title, facebook, twitter, linkedin, email, urlShortener } = props;

    // Remove any existing UTM fields from URL prior to sharing
    // otherwise they'll be duplicated with each share.
    const url = parseUrl(originalUrl, true);
    const { utm_source, utm_medium, utm_campaign, utm_content, utm_term, utm_key, ...rest } = url.query;

    const uid = generateUid();

    const getUrl = (source, medium = SOCIAL_MEDIUM) => () => {
        url.set('query', {
            ...rest,
            utm_source: source,
            utm_medium: medium,
            utm_campaign: SHARE_CAMPAIGN,
            utm_key: uid
        });

        return urlShortener(url.toString());
    }

    const trackClick = (source, medium = SOCIAL_MEDIUM) =>
        props.onShare({
            utmSource: source,
            utmMedium: medium,
            utmCampaign: SHARE_CAMPAIGN,
            utmTerm: null,
            utmContent: null,
            utmKey: uid
        });

    return <div className={classes.root}>
        {facebook && <div title="Share on Facebook" className={classes.button}>
            <FacebookShareButton url={getUrl(FACEBOOK_SOURCE)}
                quote={title}
                hashtag="#mavitra"
                beforeOnClick={() => trackClick(FACEBOOK_SOURCE)}>
                <FacebookIcon round size={size} />
            </FacebookShareButton>
        </div>}
        {twitter && <div title="Share on Twitter" className={classes.button}>
            <TwitterShareButton url={getUrl(TWITTER_SOURCE)}
                title={title}
                hashtags={['mavitra']}
                beforeOnClick={() => trackClick(TWITTER_SOURCE)}>
                <TwitterIcon round size={size} />
            </TwitterShareButton>
        </div>}
        {linkedin && <div title="Share on LinkedIn" className={classes.button}>
            <LinkedinShareButton url={getUrl(LINKEDIN_SOURCE)}
                beforeOnClick={() => trackClick(LINKEDIN_SOURCE)}>
                <LinkedinIcon round size={size} />
            </LinkedinShareButton>
        </div>}
        {email && <div title="Share by email" className={classes.button}>
            <EmailShareButton url={getUrl(EMAIL_SOURCE, EMAIL_MEDIUM)}
                subject="Shared via Mavitra"
                body={title}
                beforeOnClick={() => trackClick(EMAIL_SOURCE, EMAIL_MEDIUM)}>
                <EmailIcon round size={size} />
            </EmailShareButton>
        </div>}
    </div>;
}

ShareButtons.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    facebook: PropTypes.bool.isRequired,
    twitter: PropTypes.bool.isRequired,
    linkedin: PropTypes.bool.isRequired,
    email: PropTypes.bool.isRequired,
    size: PropTypes.number.isRequired,
    vertical: PropTypes.bool.isRequired,
    urlShortener: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired
};

ShareButtons.defaultProps = {
    size: 32,
    vertical: false,
    title: 'Check out these stores on Mavitra.',
    facebook: false,
    twitter: false,
    linkedin: false,
    email: false,
    urlShortener: url => url
};

export default ShareButtons;