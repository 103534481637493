import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import StarRating from '../../components/StarRating';
import Typography from '@material-ui/core/Typography';
import HasProductsIcon from '@material-ui/icons/BeenhereRounded';
import { withStyles } from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';
import classNames from 'classnames';

const styles = theme => ({
    card: {
        height: '100%',
        width: '100%',
        display: 'flex'
    },
    cardContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        '&:hover .hero': {
            backgroundSize: '100%'
        }
    },
    hero: {
        backgroundPosition: 'center',
        backgroundSize: '110%',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        height: '140px',
        maxHeight: '140px',
        transition: `background-size ${theme.transitions.duration.short}ms`
    },
    starRating: {
        display: 'flex',
        justifyContent: 'center'
    },
    logo: {
        alignSelf: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        height: 140,
        width: 160,
        marginTop: -70,
        padding: theme.spacing(1),
        marginBottom: 16,
        zIndex: 1
    },
    storeDetails: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    storeName: {
        fontSize: '1.3rem'
    },
    overline: {
        lineHeight: 2
    },
    hasProducts: {
        position: 'absolute',
        //backgroundColor: theme.palette.primary.main,
        color: yellow[600],
        top: 0,
        right: 0,
        padding: theme.spacing(1),
        borderRadius: '0 0 0 4px',
        zIndex: 1
    },
    score: {
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 500,
        top: 0,
        right: 0,
        padding: theme.spacing(1),
        borderRadius: '0 0 0 4px',
        zIndex: 2
    }
});

class StoreCard extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    render() {
        const { classes, store } = this.props;

        return <Card className={classes.card}>
            <CardActionArea className={classes.cardContent}
                component="a"
                href={`${window.location.origin}/stores/${store.slug}`}
                onClick={e => {
                    e.preventDefault();
                    this.props.onSelect(store);
                }}>
                {store.hasProducts && <div className={classes.hasProducts} title="We know about the products in this store">
                    <HasProductsIcon />
                </div>}
                {store.score && <div className={classes.score}>{store.score}</div>}
                <div className={classNames('hero', classes.hero)}
                    style={{ backgroundImage: `url("${store.heroImageUrl || '/images/no-hero-image.jpg'}")` }} />
                <Paper square elevation={4} className={classes.logo} style={{ backgroundImage: `url("${store.logoImageUrl || '/images/no-image.png'}")` }} />
                {Boolean(store.rating) && <div className={classes.starRating}>
                    <StarRating rating={store.rating} />
                </div>}
                <CardContent className={classes.storeDetails}>
                    <Typography gutterBottom noWrap align="center" display="block" variant="h5" className={classes.storeName}>
                        {store.name}
                    </Typography>
                    {Boolean(store.shoppingLocation || store.suburb) &&
                        <Fragment>
                            <Typography noWrap align="center" display="block" variant="overline" className={classes.overline}>
                                {store.shoppingLocation || store.suburb}
                            </Typography>
                            {Boolean(store.distance) &&
                                <Typography align="center" display="block" variant="overline" className={classes.overline}>
                                    {store.distance && `${parseInt(store.distance / 1000)} km away`}
                                </Typography>}
                        </Fragment>}
                </CardContent>
            </CardActionArea>
        </Card>;
    }
}

export default withStyles(styles)(StoreCard);