import axios from 'axios';

export default class AuthService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    setBaseUrl(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async signInExternal(externalToken, provider) {
        const headers = { 'Content-Type': 'application/json' };

        const response = await axios
            .post(`${this.baseUrl}/auth/${provider}`,
                { externalToken },
                { headers });

        return response;
    }

    async refreshToken(accessToken, refreshToken) {
        const headers = { 'Content-Type': 'application/json' };

        const response = await axios
            .post(`${this.baseUrl}/auth/refresh`,
                { accessToken, refreshToken },
                { headers });

        return response;
    }
}