import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StoreSearchContainer from '../../containers/StoreSearchContainer';
import { Subscribe } from 'unstated';
import Grid from '@material-ui/core/Grid';
import StoreCard from './StoreCard';

export default class StoreGrid extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired
    };

    render() {
        const { onSelect } = this.props;

        return <Grid container spacing={2}>
            <Subscribe to={[StoreSearchContainer]}>
                {ssc => ssc.state.stores.map(s => <Grid key={s.id} item xs={12} sm={6} md={4} xl={3}>
                    <StoreCard store={s} onSelect={onSelect} />
                </Grid>)}
            </Subscribe>
        </Grid>;
    }
}
