import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grow from '@material-ui/core/Grow';
import { DatePicker } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class UpdateShoppingListForm extends Component {
    static propTypes = {
        shoppingList: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        const { name, reminderDate } = props.shoppingList;

        this.state = {
            name,
            isForOccasion: Boolean(reminderDate),
            reminderDate,
            errors: {},
            busy: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    onDateChange = name => date => {
        this.setState({
            [name]: date
        });
    }

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { name, isForOccasion, reminderDate } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                reminderDate: isForOccasion ? reminderDate : null
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, onCancel } = this.props;
        const { name, isForOccasion, reminderDate, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <FormControlLabel control={
                <Switch color="primary"
                    checked={isForOccasion}
                    onChange={this.onCheckedChange('isForOccasion')} />}
                label="Remind me about this list" />

            <Grow in={isForOccasion} unmountOnExit>
                <div>
                    <DatePicker
                        autoOk
                        label="Remind me about it on"
                        format="d MMMM yyyy"
                        fullWidth
                        margin="normal"
                        value={reminderDate}
                        helperText={errors.reminderDate}
                        error={Boolean(errors.reminderDate)}
                        onChange={this.onDateChange('reminderDate')} />
                </div>
            </Grow>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(UpdateShoppingListForm);