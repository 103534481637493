import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppContainer from '../../containers/AppContainer';
import { Subscribe } from 'unstated';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import StarRating from '../../components/StarRating';
import withStyles from '@material-ui/core/styles/withStyles';
import ImageGrid from '../../components/ImageGrid';
import Lightbox from 'react-images';
import Button from '@material-ui/core/Button';
import ShoppingLocationContactActions from './ShoppingLocationContactActions';
import ShoppingLocationFeatureList from './ShoppingLocationFeatureList';
import { toHtmlString } from '../../helpers';
import encodeUrl from 'encodeurl';
import classNames from 'classnames';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { paragraphsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    hero: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        marginBottom: theme.spacing(1),
        width: '100%',
        height: '250px',
        [theme.breakpoints.up('md')]: {
            height: '300px'
        },
        [theme.breakpoints.up('lg')]: {
            height: '350px'
        },
        [theme.breakpoints.up('xl')]: {
            height: '400px'
        }
    },
    logoContainer: {
        display: 'flex',
        padding: theme.spacing(1)
    },
    logo: {
        alignSelf: 'center',
        maxHeight: '200px',
        maxWidth: '100%'
    },
    imageModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        },
        '& > img': {
            outline: 'none',
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    actions: {
        display: 'flex',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    block: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'stretch'
        }
    },
    map: {
        border: 0,
        margin: 0,
        padding: 0,
        flexGrow: 1,
        minHeight: 300,
        width: '100%'
    },
    gutterBottom: {
        marginBottom: theme.spacing(2)
    },
    gutterTop: {
        marginTop: theme.spacing(2)
    },
    ...paragraphsStyle(theme)
});

class ShoppingLocationDetails extends Component {
    static propTypes = {
        shoppingLocation: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired,
        onOpenParentShoppingLocation: PropTypes.func.isRequired
    };

    state = {
        addingToFavourites: false,
        imageIndex: 0,
        isImageOpen: false
    };

    constructor(props) {
        super(props);
        this.onCloseImage = this.onCloseImage.bind(this);
    }

    getOpenStatusText() {
        switch (this.props.shoppingLocation.isOpen) {
            case true: return 'Open now';
            case false: return 'Closed now';
            case null: return "We're not sure";
            default: return 'Checking...';
        }
    }

    onOpenImage(ac, image, index) {
        this.setState({
            imageIndex: index,
            isImageOpen: true
        });

        ac.onShoppingLocationGalleryViewed(this.props.shoppingLocation.id);
    }

    onCloseImage() {
        this.setState({ isImageOpen: false });
    }

    renderShoppingLocationDetails(shoppingLocation) {
        const { classes, width, googleApiKey, onOpenParentShoppingLocation } = this.props;
        const { imageIndex, isImageOpen } = this.state;

        // Convert store images into lightbox format.
        const images = shoppingLocation.images.map(img => ({
            src: img.imageUrl,
            caption: img.description
        }));

        let imageGridCols = 4.5;
        let imageGridCellHeight = 175;

        for (const breakpoint of ['md', 'sm', 'xs']) {
            if (isWidthDown(breakpoint, width)) {
                imageGridCols--;
                imageGridCellHeight -= 25;
            }
        }

        const logo = <img className={classes.logo} src={shoppingLocation.logoImageUrl || '/images/no-image.png'} alt={`${shoppingLocation.name} logo`} title={`${shoppingLocation.name} logo`} />;

        return <Fragment>
            <Hidden xsDown>
                {Boolean(shoppingLocation.heroImageUrl) && <div className={classes.hero} style={{ backgroundImage: `url("${shoppingLocation.heroImageUrl}")` }}>
                    {Boolean(shoppingLocation.logoImageUrl) &&
                        <Paper square elevation={4} className={classes.logoContainer}>
                            {logo}
                        </Paper>}
                </div>}
                {!Boolean(shoppingLocation.heroImageUrl) && <img className={classNames(classes.logo, classes.gutterBottom)} src={shoppingLocation.logoImageUrl || '/images/no-image.png'} alt={`${shoppingLocation.name} logo`} title={`${shoppingLocation.name} logo`} />}
            </Hidden>
            <Hidden smUp>
                {logo}
            </Hidden>
            {!Boolean(shoppingLocation.heroImageUrl) && <Divider className={classes.gutterBottom} />}
            {<Subscribe to={[AppContainer]}>
                {ac => <Fragment>
                    {shoppingLocation.images.length > 0 && <div className={classes.gutterBottom}>
                        <ImageGrid images={shoppingLocation.images}
                            cellHeight={imageGridCellHeight}
                            cols={imageGridCols}
                            onSelect={(img, idx) => this.onOpenImage(ac, img, idx)} />
                        <Lightbox images={images}
                            currentImage={imageIndex}
                            isOpen={isImageOpen}
                            backdropClosesModal={true}
                            showImageCount={false}
                            onClickNext={() => this.setState({ imageIndex: imageIndex + 1 })}
                            onClickPrev={() => this.setState({ imageIndex: imageIndex - 1 })}
                            onClose={this.onCloseImage} />
                    </div>}
                    <div className={classes.gutterBottom}>
                        <ShoppingLocationContactActions shoppingLocation={shoppingLocation}
                            onPhoneClick={() => ac.onShoppingLocationPhoneClicked(shoppingLocation.id)}
                            onWebsiteClick={() => ac.onShoppingLocationWebsiteClicked(shoppingLocation.id)}
                            onMapClick={() => ac.onShoppingLocationMapClicked(shoppingLocation.id)}
                            onDirectionsClick={() => ac.onShoppingLocationDirectionsClicked(shoppingLocation.id)} />
                    </div>
                </Fragment>}
            </Subscribe>}
            <Divider className={classNames(classes.gutterBottom)} />
            {Boolean(shoppingLocation.description) && <div className={classNames(classes.paragraphs, classes.gutterBottom)}>
                <Typography variant="overline">About</Typography>
                {toHtmlString(shoppingLocation.description)}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Open/Closed</Typography>
                <Typography>{this.getOpenStatusText()}</Typography>
            </div>
            {Boolean(shoppingLocation.rating) &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Average rating</Typography>
                    <StarRating rating={shoppingLocation.rating} />
                </div>}
            {shoppingLocation.features.length > 0 &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Features &amp; services</Typography>
                    <ShoppingLocationFeatureList features={shoppingLocation.features} />
                </div>}
            {Boolean(shoppingLocation.parentShoppingLocation) && <div className={classNames(classes.block, classes.gutterBottom)}>
                <Typography variant="overline">Located within</Typography>
                <Typography>{shoppingLocation.parentShoppingLocation}</Typography>
                {Boolean(shoppingLocation.parentShoppingLocationSlug) &&
                    <Button className={classes.button}
                        component="a"
                        href={`${window.location.origin}/shopping-locations/${shoppingLocation.parentShoppingLocationSlug}`}
                        color="primary"
                        size="small"
                        onClick={e => {
                            e.preventDefault();
                            onOpenParentShoppingLocation(shoppingLocation.parentShoppingLocationSlug);
                        }}>
                        See more about this location
                    </Button>}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Address</Typography>
                <Typography>{shoppingLocation.address}</Typography>
            </div>
            {Boolean(shoppingLocation.googlePlaceId) &&
                <iframe title="map"
                    frameBorder="0"
                    className={classes.map}
                    src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:${shoppingLocation.googlePlaceId}`}>
                </iframe>}
            {!Boolean(shoppingLocation.googlePlaceId) &&
                <iframe title="map"
                    frameBorder="0"
                    className={classes.map}
                    src={encodeUrl(`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${shoppingLocation.address}`)}>
                </iframe>}
        </Fragment>;
    }

    render() {
        const { classes, shoppingLocation } = this.props;

        return <div className={classes.root}>
            {this.renderShoppingLocationDetails(shoppingLocation)}
        </div>;
    }
}

export default withStyles(styles)(withWidth()(ShoppingLocationDetails));