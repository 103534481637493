import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    paper: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    title: {
        flexGrow: 1
    },
    container: {
        overflowY: 'auto'
    }
});

class SideDrawer extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        autoFullWidth: PropTypes.bool.isRequired,
        swipeable: PropTypes.bool.isRequired
    };

    static defaultProps = {
        autoFullWidth: true,
        swipeable: false
    };

    render() {
        const { classes, title, open, onClose, autoFullWidth, swipeable, children, ...rest } = this.props;
        const DrawerComponent = swipeable ? SwipeableDrawer : Drawer;
        let swipeableProps = null;

        if (swipeable) {
            swipeableProps = {
                onOpen: () => { },
                disableDiscovery: true,
                disableSwipeToOpen: true
            };
        }

        return <DrawerComponent open={open}
            onClose={onClose}
            classes={{ paper: classNames({ [classes.paper]: autoFullWidth }) }}
            {...swipeableProps}
            {...rest}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.title}>{title}</Typography>
                    <IconButton color="inherit" onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                {children}
            </div>
        </DrawerComponent>;
    }
}

export default withStyles(styles)(SideDrawer);