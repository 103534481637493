import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import App from './App';
import ConfigService from './services/ConfigService';
import { generateUid } from './helpers';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';

function getIdFromStorage(storage, key) {
    if (storage) {
        try {
            let id = storage.getItem(key);

            if (!Boolean(id)) {
                id = generateUid();
                storage.setItem(key, id);
            }

            return id;
        } catch {
            // Storage disabled. ID will be held in memory only.
            return generateUid();
        }
    } else {
        // Storage not available. ID will be held in memory only.
        return generateUid();
    }
}

function useOriginalRefererForApiCalls() {
    // Pass through the original referer as a customer header on all API calls so we
    // can track it for analytics events. We can't use the standard Referer header
    // because its value is always the URL of our own portal.
    axios.defaults.headers.common['X-Referer'] = document.referrer;
}

function generateRequestTrackingIds() {
    // These IDs are used to correlate requests on the back-end.
    axios.defaults.headers.common['X-Origin-Id'] = getIdFromStorage(window.localStorage, 'OriginId');
    axios.defaults.headers.common['X-Session-Id'] = getIdFromStorage(window.sessionStorage, 'SessionId');
}

function runApp(config) {
    const app = <Fragment>
        <Helmet defaultTitle="Retail, Reimagined">
            <meta name="description" content="Mavitra is a powerful retail search platform that helps you find your favourite brands and products in stores near you" />
            <meta name="keywords" content="Retail, Australia, Search, Shopping, Platform" />
            <meta property="og:title" content="Retail, Reimagined" />
            <meta property="og:description" content="Mavitra is a powerful retail search platform that helps you find your favourite brands and products in stores near you" />
        </Helmet>
        <App config={config} />
    </Fragment>;

    ReactDOM.render(app, document.getElementById('root'));

    serviceWorker.unregister();

    // if (config.production) {
    //     serviceWorker.register();
    // }
}

useOriginalRefererForApiCalls();
generateRequestTrackingIds();

// Load app config then spin up app.
new ConfigService()
    .load('/config.json')
    .then(runApp)
    .catch(error => console.log('Failed to load app config.', error.message));
