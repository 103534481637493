import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default class Page extends Component {
    static propTypes = {
        title: PropTypes.string
    };

    render() {
        const { title, children } = this.props;

        return <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta property="og:url" content={document.location.href} />
            </Helmet>
            {children}
        </Fragment>;
    }
}