import React from 'react';
import PropTypes from 'prop-types';
import InStockIcon from '@material-ui/icons/CheckCircle';
import LowStockIcon from '@material-ui/icons/CheckCircleOutline';
import OutOfStockIcon from '@material-ui/icons/Cancel';
import UnknownStockIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { green, orange, red, grey } from '@material-ui/core/colors';
import camelCase from 'lodash/camelCase';
import classNames from 'classnames';
import { useWidth } from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    inStock: {
        color: green[500]
    },
    lowStock: {
        color: orange[500]
    },
    outOfStock: {
        color: red[500]
    },
    unknownStock: {
        color: grey[500]
    },
    stockStatusIcon: {
        marginRight: theme.spacing(1)
    }
}));

function getShortStockStatus(stockStatus) {
    switch (stockStatus) {
        case 'inStock': return 'Yes';
        case 'lowStock': return 'Low';
        case 'outOfStock': return 'No';
        default: return '';
    }
}

function getStockStatusIcon(stockStatus) {
    switch (stockStatus) {
        case 'inStock': return InStockIcon;
        case 'lowStock': return LowStockIcon;
        case 'outOfStock': return OutOfStockIcon;
        default: return UnknownStockIcon;
    }
}

export default function StockStatus({ stockStatus }) {
    const classes = useStyles();
    const width = useWidth();
    const stockStatusKey = camelCase(stockStatus);
    const StockStatusIcon = getStockStatusIcon(stockStatusKey);


    return <div className={classNames(classes.root, classes[stockStatusKey])}>
        <StockStatusIcon color="inherit" className={classes.stockStatusIcon} />
        <Typography noWrap color="inherit" variant="overline">{width === 'xs' ? getShortStockStatus(stockStatusKey) : stockStatus}</Typography>
    </div>
}

StockStatus.propTypes = {
    stockStatus: PropTypes.string.isRequired
};