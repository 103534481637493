import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppContainer from '../../containers/AppContainer';
import StoreContainer from '../../containers/StoreContainer';
import { Provider, Subscribe } from 'unstated';
import Grid from '@material-ui/core/Grid';
import ProductGrid from '../../components/ProductGrid';
import Typography from '@material-ui/core/Typography';
import ShareButtons from '../../components/ShareButtons';
import Divider from '@material-ui/core/Divider';
import StarRating from '../../components/StarRating';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import ImageGrid from '../../components/ImageGrid';
import Lightbox from 'react-images';
import BusyButton from '../../components/BusyButton';
import NearbyStoreCard from '../../components/NearbyStoreCard';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star';
import StoreContactActions from '../../components/StoreContactActions';
import StoreFeatureList from '../../components/StoreFeatureList';
import { toHtmlString } from '../../helpers';
import encodeUrl from 'encodeurl';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { paragraphsStyle, spinnerStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 500,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
    },
    logoContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    },
    logo: {
        maxHeight: '200px',
        maxWidth: '100%'
    },
    imageModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2)
        },
        '& > img': {
            outline: 'none',
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    map: {
        border: 0,
        margin: 0,
        padding: 0,
        flexGrow: 1,
        minHeight: 300,
        width: '100%'
    },
    block: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        marginTop: theme.spacing(1)
    },
    products: {
        marginBottom: theme.spacing(2)
    },
    gutterBottom: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    gutterTop: {
        marginTop: theme.spacing(2)
    },
    ...paragraphsStyle(theme),
    ...spinnerStyle(theme)
});

class StoreDetails extends Component {
    static propTypes = {
        storeId: PropTypes.string.isRequired,
        query: PropTypes.string,
        storeSearchService: PropTypes.object.isRequired,
        productSearchService: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired,
        onOpenStore: PropTypes.func.isRequired,
        onOpenShoppingLocation: PropTypes.func.isRequired,
        onOpenNearbyStore: PropTypes.func.isRequired
    };

    state = {
        addingToFavourites: false,
        imageIndex: 0,
        isImageOpen: false
    };

    constructor(props) {
        super(props);

        this.storeContainer = new StoreContainer(props.storeSearchService, props.productSearchService);

        this.onCloseImage = this.onCloseImage.bind(this);
        this.onAddStoreToFavourites = this.onAddStoreToFavourites.bind(this);
    }

    async componentDidMount() {
        const { storeId, query } = this.props;

        await this.storeContainer.load(storeId, query, false);
    }

    getOpenStatusText() {
        switch (this.storeContainer.state.store.isOpen) {
            case true: return 'Open now';
            case false: return 'Closed now';
            case null: return "We're not sure";
            default: return 'Checking...';
        }
    }

    onOpenImage(ac, image, index) {
        this.setState({
            imageIndex: index,
            isImageOpen: true
        });

        ac.onStoreGalleryViewed(this.props.storeId);
    }

    onCloseImage() {
        this.setState({ isImageOpen: false });
    }

    async onAddStoreToFavourites(ac) {
        this.setState({ addingToFavourites: true });

        try {
            const customerId = ac.getClaim('sub');
            await this.storeContainer.addStoreToFavourites(customerId);
        } finally {
            this.setState({ addingToFavourites: false });
        }
    }

    onOpenProduct(storeSlug, productSlug) {
        this.props.history.push({
            pathname: `/stores/${storeSlug}/products/${productSlug}`,
            state: { canGoBack: true }
        })
    }

    renderStoreDetails(store, products) {
        const { classes, googleApiKey, onOpenStore, onOpenShoppingLocation, onOpenNearbyStore } = this.props;
        const { addingToFavourites, imageIndex, isImageOpen } = this.state;

        // Convert store images into lightbox format.
        const images = store.images.map(img => ({
            src: img.imageUrl,
            caption: img.description
        }));

        return <Fragment>
            <Subscribe to={[AppContainer]}>
                {ac => <Fragment>
                    {/* TODO: Lift admin-only condition when ready */}
                    {Boolean(ac.getClaim('admin', false)) && products.length > 0 && <Fragment>
                        <div className={classNames(classes.container, classes.products)}>
                            <ProductGrid fixed store={store} products={products} onSelect={p => this.onOpenProduct(store.storeSlug, p.slug)} />
                        </div>
                        <Divider className={classes.gutterBottom} />
                    </Fragment>}
                    <div className={classes.header}>
                        <div className={classes.logoContainer}>
                            <img className={classes.logo} src={store.logoImageUrl || '/images/no-image.png'} alt={`${store.name} logo`} title={`${store.name} logo`} />
                        </div>
                        <ShareButtons facebook
                            twitter
                            email
                            title={`${store.name} is on Mavitra.`}
                            url={`${document.location.origin}/stores/${store.storeSlug}`}
                            size={48}
                            vertical
                            urlShortener={ac.shortenUrl}
                            onShare={utms => ac.onShareStoreClicked(store.id, utms)} />
                    </div>
                    <Divider className={classes.gutterBottom} />
                    {store.images.length > 0 && <div className={classes.gutterBottom}>
                        <ImageGrid images={store.images}
                            cellHeight={125}
                            onSelect={(img, idx) => this.onOpenImage(ac, img, idx)} />
                        <Lightbox images={images}
                            currentImage={imageIndex}
                            isOpen={isImageOpen}
                            backdropClosesModal={true}
                            showImageCount={false}
                            onClickNext={() => this.setState({ imageIndex: imageIndex + 1 })}
                            onClickPrev={() => this.setState({ imageIndex: imageIndex - 1 })}
                            onClose={this.onCloseImage} />
                    </div>}
                    {ac.hasToken() && <Fragment>
                        {store.isFavourite && <Typography align="center" display="block" color="primary" variant="overline" gutterBottom>
                            This is one of your favourite stores
                        </Typography>}
                        {!store.isFavourite && <BusyButton color="primary"
                            busy={addingToFavourites}
                            style={{ marginBottom: 8 }}
                            onClick={() => this.onAddStoreToFavourites(ac)}>
                            <StarIcon style={{ marginRight: 8 }} />Add to your favourites
                        </BusyButton>}
                    </Fragment>}
                    <div className={classes.gutterBottom}>
                        <StoreContactActions store={store}
                            onPhoneClick={() => ac.onStorePhoneClicked(store.id)}
                            onWebsiteClick={() => ac.onStoreWebsiteClicked(store.id)}
                            onMapClick={() => ac.onStoreMapClicked(store.id)}
                            onDirectionsClick={() => ac.onStoreDirectionsClicked(store.id)} />
                    </div>
                </Fragment>}
            </Subscribe>
            <Divider className={classNames(classes.gutterBottom)} />
            {Boolean(store.description) && <div className={classNames(classes.block, classes.paragraphs, classes.gutterBottom)}>
                <Typography variant="overline">About</Typography>
                {toHtmlString(store.description)}
                {Boolean(store.storeSlug) &&
                    <Button className={classes.button}
                        component="a"
                        href={`${window.location.origin}/stores/${store.storeSlug}`}
                        color="primary"
                        size="small"
                        onClick={e => {
                            e.preventDefault();
                            onOpenStore(store.storeSlug);
                        }}>
                        See more about this store
                    </Button>}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Open/Closed</Typography>
                <Typography>{this.getOpenStatusText()}</Typography>
            </div>
            {Boolean(store.rating) &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Average rating</Typography>
                    <StarRating rating={store.rating} />
                </div>}
            {store.features.length > 0 &&
                <div className={classes.gutterBottom}>
                    <Typography variant="overline">Features &amp; services</Typography>
                    <StoreFeatureList features={store.features} />
                </div>}
            {Boolean(store.shoppingLocation) && <div className={classNames(classes.block, classes.gutterBottom)}>
                <Typography variant="overline">Located within</Typography>
                <Typography>{store.shoppingLocation}</Typography>
                {Boolean(store.shoppingLocationSlug) &&
                    <Button className={classes.button}
                        component="a"
                        href={`${window.location.origin}/shopping-locations/${store.shoppingLocationSlug}`}
                        color="primary"
                        size="small"
                        onClick={e => {
                            e.preventDefault();
                            onOpenShoppingLocation(store.shoppingLocationSlug);
                        }}>
                        See more about this location
                    </Button>}
            </div>}
            <div className={classes.gutterBottom}>
                <Typography variant="overline">Address</Typography>
                <Typography>{store.address}</Typography>
            </div>
            <div className={classes.gutterBottom}>
                {Boolean(store.googlePlaceId) &&
                    <iframe title="map"
                        frameBorder="0"
                        className={classes.map}
                        src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:${store.googlePlaceId}`}>
                    </iframe>}
                {!Boolean(store.googlePlaceId) &&
                    <iframe title="map"
                        frameBorder="0"
                        className={classes.map}
                        src={encodeUrl(`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${store.address}`)}>
                    </iframe>}
            </div>
            {store.nearbyStores.length > 0 && <div className={classes.gutterBottom}>
                <Typography variant="overline">Nearby here</Typography>
                <Grid container spacing={2}>
                    {store.nearbyStores.map(nearbyStore => <Grid key={nearbyStore.id} item xs={12}>
                        <NearbyStoreCard store={nearbyStore} onSelect={() => onOpenNearbyStore(nearbyStore.id, nearbyStore.slug)} />
                    </Grid>)}
                </Grid>
            </div>}
        </Fragment>;
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Provider inject={[this.storeContainer]}>
                <Subscribe to={[StoreContainer]}>
                    {sc =>
                        Boolean(sc.state.store)
                            ? this.renderStoreDetails(sc.state.store, sc.state.products)
                            : <CircularProgress className={classes.spinner} size={90} thickness={2.4} />}
                </Subscribe>
            </Provider>
        </div>;
    }
}

export default withRouter(withStyles(styles)(StoreDetails));