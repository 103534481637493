import { Container } from 'unstated';

export default class StoreContainer extends Container {
    constructor(storeSearchService, productSearchService) {
        super();

        this.storeSearchService = storeSearchService;
        this.productSearchService = productSearchService;
    }

    state = {
        store: null,
        products: []
    };

    async load(idOrSlug, query = null, ignoreCache = false, loaded = null) {
        const storeResponse = await this.storeSearchService.getStoreDetails(idOrSlug, ignoreCache);

        if (storeResponse.ok) {
            const nextState = { store: storeResponse.data };

            if (Boolean(query)) {
                const productsResponse = await this.productSearchService.search(storeResponse.data.id, query);
                nextState.products = productsResponse.ok ? productsResponse.products : [];
            }

            this.setState(nextState, loaded ? () => loaded(this.state.store) : null);
        }

        return storeResponse.ok;
    }

    async addStoreToFavourites(customerId) {
        const { store } = this.state;
        const response = await this.storeSearchService.addStoreToFavourites(customerId, store.id);

        if (response.ok) {
            // Reload store.
            await this.load(store.id, true);
        }

        return response.ok;
    }
}