import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../../containers/AppContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import UserMenu from '../../components/UserMenu';
import Footer from '../../components/Footer';
import SearchInput from '../../components/SearchInput';
import Chip from '@material-ui/core/Chip';
import LocationButton from '../../components/LocationButton';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import queryString from 'query-string';
import slugify from 'slugify';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router';
import SelectImageDialog from '../../components/SelectImageDialog';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = theme => ({
    root: {
        color: theme.palette.common.white,
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundImage: 'url(/images/emaar-mall.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        zIndex: -1
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0
    },
    menuArea: {
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        margin: theme.spacing(2),
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    userArea: {
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        margin: theme.spacing(2),
        '& > :not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    row: {
        flexShrink: 0,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(3)
        }
    },
    searchBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),

    },
    locationBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1)
    },
    locationButton: {
        marginRight: theme.spacing(1)
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '390px',
        width: '90%'
    },
    tagline: {
        fontFamily: 'Dancing Script',
        fontSize: '1.5rem',
        textAlign: 'center',
        marginTop: theme.spacing(1),
        marginBottom: 0
    },
    trending: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& > hr': {
            flex: 1,
            color: theme.palette.common.white,
            height: '1px',
            padding: 0,
            margin: 10
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    chip: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1)
        }
    }
});

class Home extends Component {
    static propTypes = {
        storeSearchService: PropTypes.object.isRequired,
        blobService: PropTypes.object.isRequired
    };

    state = {
        placeholder: '',
        trendingSearches: [],
        imageResults: [],
        selectImageDialogOpen: false,
        loaded: false
    };

    constructor(props) {
        super(props);

        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onImageSelected = this.onImageSelected.bind(this);
        this.onImageSearchResultSelected = this.onImageSearchResultSelected.bind(this);
        this.onCancelImageSearch = this.onCancelImageSearch.bind(this);
    }

    async componentDidMount() {
        const response = await this.props.storeSearchService.getTrendingSearches();

        if (response.ok) {
            this.setState({
                trendingSearches: response.data,
                loaded: true
            });
        }
    }

    onUseMyLocationChanged(ac) {
        if (ac.isTrackingPosition()) {
            ac.stopTrackingPosition();
        } else {
            ac.startTrackingPosition();
        }
    }

    async getSearchSuggestions(app, value) {
        // No need to ask for (saved search) suggestions as no user is logged in.
        if (!Boolean(value) && !app.hasToken()) {
            return null;
        }

        const response = await this.props.storeSearchService.getSuggestions(value);

        if (response.ok) {
            return response.data;
        }

        return null;
    }

    onRunSavedSearch(query) {
        this.props.history.push('/search' + query);
    }

    onSearch(query, shoppingLocation, category, ac, trending = false) {
        if (!Boolean(query)) {
            return;
        }

        const params = {
            q: slugify(query)
        };

        if (Boolean(shoppingLocation)) {
            params.loc = shoppingLocation;
        }

        if (Boolean(category)) {
            params.cat = category;
        }

        const { devicePosition, devicePositionUnavailable } = ac.state;

        if (Boolean(devicePosition) && !devicePositionUnavailable) {
            params.lat = devicePosition.latitude;
            params.lng = devicePosition.longitude;
        }

        this.props.history.push({
            pathname: '/search',
            search: queryString.stringify(params, { sort: false }),
            state: { trending }
        });
    }

    async onImageSelected(file) {
        if (file == null) {
            return false;
        }

        const { blobService, imageSearchService } = this.props;
        const { blobUrl } = await blobService.upload('search-images', file);
        const { ok, results } = await imageSearchService.analyzeImage(blobUrl);

        if (ok) {
            if (results.length === 0) {
                alert('No objects were identified in the selected image.');
            } else if (results.length === 1) {
                this.onImageSearchResultSelected(results[0]);
                return true;
            } else {
                this.setState({
                    imageResults: results,
                    selectImageDialogOpen: true
                });
            }
        } else {
            alert('We were unable to search using the selected image.');
        }

        return false;
    }

    onImageSearchResultSelected(result) {
        const params = {
            q: slugify(result.query)
        };

        this.props.history.push({
            pathname: '/search',
            search: queryString.stringify(params),
            state: {
                imageSearch: true
            }
        });
    }

    onCancelImageSearch() {
        this.setState({
            imageResults: [],
            selectImageDialogOpen: false
        });
    }

    onSignIn() {
        this.props.history.push('/sign-in');
    }

    onSignOut(app) {
        app.signOut();
    }

    renderUserMenu(ac, classes, width) {
        const isSmallViewport = isWidthDown('xs', width);

        return <div className={classes.userArea}>
            {ac.hasToken() ?
                <div>
                    <UserMenu fullName={`${ac.getClaim('given_name')} ${ac.getClaim('family_name')}`}
                        emailAddress={ac.getClaim('email')}
                        imageUrl={ac.getClaim('picture')}
                        isAdmin={Boolean(ac.getClaim('admin', false))}
                        onShowAccount={() => this.props.history.push('/my-profile')}
                        onSignOut={() => this.onSignOut(ac)} />
                </div> :
                <Button color="inherit" size={isSmallViewport ? 'medium' : 'large'} onClick={() => this.onSignIn()}>Sign in</Button>}
        </div>
    }

    render() {
        const { classes, width } = this.props;
        const { trendingSearches, selectImageDialogOpen, imageResults, loaded } = this.state;
        const isSmallViewport = isWidthDown('xs', width);

        return <Subscribe to={[AppContainer]}>
            {ac => <div className={classes.root}>
                {!loaded && <LinearProgress className={classes.progress} />}
                <div className={classes.overlay} />
                <div className={classes.menuArea}>
                    <Button color="inherit" size={isSmallViewport ? 'medium' : 'large'} href="https://about.mavitra.com">About</Button>
                    <Button color="inherit" size={isSmallViewport ? 'medium' : 'large'} href="https://retailer.mavitra.com">Retailers</Button>
                </div>
                {this.renderUserMenu(ac, classes, width)}

                <Grid container justify="center" className={classes.row}>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <img className={classes.logo} src="/images/mavitra-logo-white.png" alt="Mavitra" title="Mavitra" />
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.tagline}>Retail, Reimagined</p>
                    </Grid>
                </Grid>

                <Grid container justify="center" className={classes.row}>
                    <Grid item xs={12} sm={9} lg={6}>
                        <Paper className={classes.searchBar}>
                            <SearchInput
                                // placeholder={placeholder}
                                imageSearch={true}
                                getSuggestions={v => this.getSearchSuggestions(ac, v)}
                                onRunSavedSearch={q => this.onRunSavedSearch(q)}
                                onSearch={(q, sl, cat) => this.onSearch(q, sl, cat, ac)}
                                onImageSelected={this.onImageSelected}
                                actions={<LocationButton location={ac.state.deviceLocationName}
                                    showLocation={ac.state.deviceLocationNameOpen}
                                    disabled={ac.state.devicePositionUnavailable}
                                    onClick={() => this.onUseMyLocationChanged(ac)} />} />
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={12} sm={9} lg={6} className={classes.trending}>
                        <hr />
                        <TrendingUpIcon className={classes.icon} />
                        <Typography variant="overline">Trending now</Typography>
                        <hr />
                    </Grid>
                </Grid>

                <Grid container justify="center" className={classes.row}>
                    <Grid item xs={12} sm={9} lg={6} className={classes.chips}>
                        {trendingSearches.map((ts, i) =>
                            <Fade key={i} in={true} timeout={500 * (i + 1)}>
                                <Chip title={`Search for ${ts} is trending now`}
                                    className={classes.chip}
                                    clickable
                                    color="primary"
                                    icon={i === 0 ? <WhatsHotIcon /> : null}
                                    label={ts}
                                    onClick={() => this.onSearch(ts, null, null, ac, true)} />
                            </Fade>)}
                    </Grid>
                </Grid>

                <SelectImageDialog open={selectImageDialogOpen}
                    images={imageResults}
                    onSelectImage={this.onImageSearchResultSelected}
                    onClose={this.onCancelImageSearch} />

                <Footer />
            </div>}
        </Subscribe>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Home)));