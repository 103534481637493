import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import AppContainer from '../containers/AppContainer';
import UserMenu from './UserMenu';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import SearchInput from './SearchInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import LocationButton from './LocationButton';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import slugify from 'slugify';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    appBar: {
        height: '84px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: '133px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        backgroundImage: 'url(/images/emaar-mall.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        zIndex: -1
    },
    spacer: {
        // Offset to account for app bar.
        minHeight: '84px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '133px'
        }
    },
    signInButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    searchBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1)
        }
    },
    logo: {
        display: 'block',
        maxHeight: '48px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '38px'
        }
    }
});

class Header extends Component {
    static propTypes = {
        storeSearchService: PropTypes.object.isRequired,
        searchText: PropTypes.string,
        onSearch: PropTypes.func
    };

    static defaultProps = {
        searchText: ''
    };

    constructor(props) {
        super(props);
        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    onSignIn() {
        this.props.history.push({
            pathname: '/sign-in',
            state: {
                from: this.props.location
            }
        });
    }

    onSignOut(app) {
        app.signOut().then(this.props.history.push('/'));
    }

    async getSearchSuggestions(app, value) {
        // No need to ask for (saved search) suggestions as no user is logged in.
        if (!Boolean(value) && !app.hasToken()) {
            return null;
        }

        const response = await this.props.storeSearchService.getSuggestions(value);

        if (response.ok) {
            return response.data;
        }

        return null;
    }

    navigate(params, coords) {
        if (Boolean(coords)) {
            params.lat = coords.latitude;
            params.lng = coords.longitude;
        }

        this.props.history.push({
            pathname: '/search',
            search: queryString.stringify(params, { sort: false })
        });
    }

    onRunSavedSearch(query) {
        this.props.history.push('/search' + query);
    }

    onSearch(query, shoppingLocation, category, coords) {
        if (!Boolean(query)) {
            return;
        }

        const params = {
            q: slugify(query)
        };

        if (Boolean(shoppingLocation)) {
            params.loc = shoppingLocation;
        }

        if (Boolean(category)) {
            params.cat = category;
        }

        this.navigate(params, coords);
    }

    onUseMyLocationChanged(ac) {
        if (ac.isTrackingPosition()) {
            ac.stopTrackingPosition();
        } else {
            ac.startTrackingPosition();
        }
    }

    renderUserMenu(ac, classes) {
        return ac.hasToken()
            ? <UserMenu fullName={`${ac.getClaim('given_name')} ${ac.getClaim('family_name')}`}
                emailAddress={ac.getClaim('email')}
                imageUrl={ac.getClaim('picture')}
                isAdmin={Boolean(ac.getClaim('admin', false))}
                onShowAccount={() => this.props.history.push('/my-profile')}
                onSignOut={() => this.onSignOut(ac)} />
            : <Button color="inherit" size="large" className={classes.signInButton} onClick={() => this.onSignIn()}>Sign in</Button>;
    }

    render() {
        const { classes, searchText } = this.props;
        const onSearch = this.props.onSearch || this.onSearch;

        return <Subscribe to={[AppContainer]}>
            {ac => <Fragment>
                <AppBar className={classes.appBar} color="secondary">
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item container xs={12} lg={10} justify="center" alignItems="center">
                            <Grid item xs={12} sm={9} md={3}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Link to="/">
                                        <img className={classes.logo} src="/images/mavitra-logo-white.png" alt="Mavitra" title="Mavitra" />
                                    </Link>
                                    <Hidden mdUp>
                                        {this.renderUserMenu(ac, classes)}
                                    </Hidden>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={9} md={6}>
                                <Paper className={classes.searchBar}>
                                    <SearchInput autoFocus={false}
                                        initialValue={searchText}
                                        getSuggestions={v => this.getSearchSuggestions(ac, v)}
                                        onRunSavedSearch={q => this.onRunSavedSearch(q)}
                                        onSearch={(q, sl, cat) => onSearch(q, sl, cat, ac.state.devicePosition)}
                                        actions={<LocationButton location={ac.state.deviceLocationName}
                                            showLocation={ac.state.deviceLocationNameOpen}
                                            disabled={ac.state.devicePositionUnavailable}
                                            onClick={() => this.onUseMyLocationChanged(ac)} />} />
                                </Paper>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    {this.renderUserMenu(ac, classes)}
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <div className={classes.overlay} />
                </AppBar>
                <div className={classes.spacer} />
            </Fragment>}
        </Subscribe>;
    }
}

export default withRouter(withStyles(styles)(Header));