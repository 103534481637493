import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import BusyButton from '../../components/BusyButton';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { readErrors } from '../../helpers';
import { drawerActionsStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'stretch',
        padding: theme.spacing(2),
        width: 450,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    ...drawerActionsStyle(theme)
});

class CreateSavedSearchForm extends Component {
    static propTypes = {
        locationName: PropTypes.string,
        hasLocation: PropTypes.bool,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    };

    static defaultProps = {
        locationName: 'Unknown',
        hasLocation: false
    };

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            saveLocation: props.hasLocation,
            errors: {},
            busy: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    onCheckedChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    async onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { onSave } = this.props;
        const { name, description, saveLocation } = this.state;

        try {
            this.setState({ busy: true });

            await onSave({
                name,
                description,
                saveLocation
            });
        }
        catch (error) {
            const errors = readErrors(error.response);

            if (errors != null) {
                this.setState({
                    errors, busy: false
                });
            } else {
                throw error;
            }
        }
    }

    render() {
        const { classes, locationName, hasLocation, onCancel } = this.props;
        const { name, description, saveLocation, errors, busy } = this.state;

        return <form className={classes.root} noValidate onSubmit={this.onSubmit}>
            <TextField
                autoFocus
                id="name"
                label="Name"
                required
                margin="normal"
                value={name}
                helperText={errors.name}
                error={Boolean(errors.name)}
                onChange={this.onChange('name')} />

            <TextField
                id="description"
                label="Description"
                margin="normal"
                multiline
                rows={3}
                value={description}
                helperText={errors.description}
                error={Boolean(errors.description)}
                onChange={this.onChange('description')} />

            <FormControl>
                <FormControlLabel disabled={!hasLocation}
                    control={
                        <Switch color="primary"
                            checked={saveLocation}
                            onChange={this.onCheckedChange('saveLocation')} />}
                    label="Save my location for this search" />
                    {saveLocation && <FormHelperText>We'll look for stores near {locationName} every time you run this search.</FormHelperText>}
            </FormControl>

            <div className={classes.drawerActions}>
                <BusyButton busy={busy} variant="contained" color="primary" type="submit">Save</BusyButton>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>;
    }
}

export default withStyles(styles)(CreateSavedSearchForm);