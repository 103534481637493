export default class ApiService {
    getRequestHeaders(accessToken) {
        const headers = {};

        if (Boolean(accessToken)) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return headers;
    }
}