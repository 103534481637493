import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Search from '@material-ui/icons/Search';
import ImageSearch from '@material-ui/icons/ImageSearch';
import Clear from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '40px'
    },
    input: {
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    fileInput: {
        display: 'none'
    },
    container: {
        position: 'relative'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: theme.zIndex.modal,
        left: 0,
        right: 0,
        overflowY: 'auto',
        maxHeight: 36 * 8,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 36 * 5
        }
    },
    sectionTitle: {
        fontStyle: 'italic',
        padding: theme.spacing(1)
    },
    suggestion: {
        display: 'block',
    },
    suggestionContext: {
        color: grey[500],
        fontWeight: 100
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    backdrop: {
        backgroundColor: theme.palette.action.disabledBackground,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
});

class SearchInput extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        initialValue: PropTypes.string,
        placeholder: PropTypes.string,
        imageSearch: PropTypes.bool.isRequired,
        minLength: PropTypes.number.isRequired,
        maxLength: PropTypes.number.isRequired,
        maxFileSize: PropTypes.number.isRequired,
        delay: PropTypes.number.isRequired,
        actions: PropTypes.node,
        getSuggestions: PropTypes.func.isRequired,
        onRunSavedSearch: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onImageSelected: PropTypes.func,
        onClear: PropTypes.func
    };

    static defaultProps = {
        autoFocus: true,
        placeholder: "I'm looking for...",
        initialValue: '',
        imageSearch: false,
        minLength: 2,
        maxLength: 250,
        maxFileSize: 4096,
        delay: 300
    };

    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue,
            hasSearchValue: Boolean(props.initialValue),
            suggestions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.onImageSelected = this.onImageSelected.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    renderSectionTitle(section) {
        return Boolean(section.title)
            ? <Typography className={this.props.classes.sectionTitle} component="div" variant="caption">{section.title}</Typography>
            : null;
    }

    renderSuggestion(suggestion, { query, isHighlighted }) {
        const matches = match(suggestion.text, query);
        const parts = parse(suggestion.text, matches);

        return <ListItem button selected={isHighlighted}>
            <div>
                {parts.map((part, index) => {
                    return part.highlight ?
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span> :
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>;
                })}
                {Boolean(suggestion.shoppingLocation) && <span className={this.props.classes.suggestionContext}> at <span style={{ textTransform: 'capitalize' }}>{suggestion.shoppingLocation}</span></span>}
                {Boolean(suggestion.category) && <span className={this.props.classes.suggestionContext}> in <span style={{ textTransform: 'capitalize' }}>{suggestion.category}</span></span>}
            </div>
        </ListItem>;
    }

    shouldRenderSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 || inputLength >= this.props.minLength;
    }

    getSectionSuggestions(section) {
        return section.items;
    }

    async getSuggestions(value, load) {
        if (this.timeout != null) {
            clearTimeout(this.timeout);
        }

        // Only fetch suggestions if no input has been received for delay ms.
        const suggestions = await new Promise(res => this.timeout = setTimeout(() => load(value).then(res), this.props.delay));

        this.timeout = null;

        return suggestions;
    }

    getSuggestionValue = (suggestion) => suggestion.text;

    clear() {
        this.setState({
            value: '',
            hasSearchValue: false
        }, () => this.input.focus());

        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    search() {
        const { onSearch } = this.props;
        const { value } = this.state;

        this.setState({
            hasSearchValue: Boolean(value)
        });

        onSearch(value);
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.search();
    }

    handleSuggestionsFetchRequested = async ({ value }) => {
        const searchSuggestions = await this.getSuggestions(value, this.props.getSuggestions);
        const sections = [];

        if (searchSuggestions != null) {
            const { savedSearches, suggestions } = searchSuggestions;

            if (savedSearches.length > 0) {
                sections.push({
                    title: 'Recently saved searches',
                    items: savedSearches
                });
            }

            if (suggestions.length > 0) {
                sections.push({
                    title: savedSearches.length > 0 ? 'Suggested searches' : null,
                    items: suggestions
                });
            }
        }

        if (!this.unmounted) {
            this.setState({ suggestions: sections });
        }
    };

    handleSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    handleChange = (_, { newValue }) => {
        this.setState({ value: newValue });
    };

    handleSuggestionSelected = (_, { suggestion }) => {
        if (Boolean(suggestion.query)) {
            this.props.onRunSavedSearch(suggestion.query);
        } else {
            // TODO: We could potentially go straight to the store here (if suggestion.storeId !== null)
            this.props.onSearch(suggestion.text, suggestion.shoppingLocation, suggestion.category);
        }
    };

    handleKeyDown(e) {
        // Escape key clears input.
        if (e.keyCode === 27) {
            this.clear();
        }
    }

    async onImageSelected(event) {
        if (!this.props.onImageSelected) {
            throw Error('No onImageSelected handler was specified.');
        }

        const { files } = event.target;

        if (files.length > 0) {
            const maxFileSizeBytes = this.props.maxFileSize * 1024;

            if (files[0].size < maxFileSizeBytes) {
                let navigating;
                try {
                    this.setState({ busy: true });
                    navigating = await this.props.onImageSelected(files[0]);
                } finally {
                    if (!navigating) {
                        this.setState({ busy: false });
                    }
                }
            } else {
                window.alert(`Image is too large. Please use another image.`);
            }
        }
    }

    renderInputComponent(inputProps) {
        const { classes, actions, maxLength, inputRef = () => { }, ref, ...other } = inputProps;
        const { hasSearchValue } = this.state;

        const startAdornment = hasSearchValue ?
            <InputAdornment position="start">
                <IconButton onClick={() => this.clear()}>
                    <Clear />
                </IconButton>
            </InputAdornment> :
            null;

        const endAdornment = <InputAdornment position="end">
            {Boolean(actions) && actions}
            {this.props.imageSearch && <IconButton onClick={() => this.fileInput.click()}>
                <ImageSearch />
                <input type="file"
                    accept="image/*"
                    className={classes.fileInput}
                    onChange={this.onImageSelected}
                    ref={input => this.fileInput = input} />
            </IconButton>}
            <IconButton color="primary" onClick={() => this.search()}>
                <Search />
            </IconButton>
        </InputAdornment>;

        return <FormControl fullWidth>
            <InputBase id="search"
                classes={{
                    input: classes.input
                }}
                inputProps={{
                    autoComplete: "off",
                    maxLength
                }}
                inputRef={node => {
                    this.input = node;
                    ref(node);
                    inputRef(node);
                }}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                {...other} />
        </FormControl>;
    }

    render() {
        const { classes, initialValue, imageSearch, minLength, maxLength, maxFileSize, delay, getSuggestions, onRunSavedSearch, onSearch, onImageSelected, ...rest } = this.props;
        const { autoFocus, placeholder, value, busy } = this.state;

        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            shouldRenderSuggestions: this.shouldRenderSuggestions,
            multiSection: true,
            getSectionSuggestions: this.getSectionSuggestions,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            onSuggestionSelected: this.handleSuggestionSelected,
            renderSectionTitle: this.renderSectionTitle,
            renderSuggestion: this.renderSuggestion
        };

        return <form className={classes.root} onSubmit={e => this.handleSubmit(e)}>
            <FormControl fullWidth>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        autoFocus,
                        placeholder,
                        value,
                        maxLength,
                        onChange: this.handleChange,
                        onKeyDown: this.handleKeyDown,
                        ...rest
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )} />
            </FormControl>
            {busy && <div className={classes.backdrop}><CircularProgress size={80} /></div>}
        </form>;
    }
}

export default withStyles(styles)(SearchInput);