import { Aborter, AnonymousCredential, BlobURL, BlockBlobURL, ContainerURL, ServiceURL, StorageURL } from '@azure/storage-blob';
import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class BlobService extends ApiService {
    constructor(apiBaseUrl, getAccessToken, blobStorageBaseUrl) {
        super();

        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
        this.blobStorageBaseUrl = blobStorageBaseUrl;
    }

    getBlobUrl(containerName, blobName) {
        if (!Boolean(blobName)) {
            return '';
        }

        return `${this.blobStorageBaseUrl}/${containerName}/${blobName}`;
    }

    async upload(containerName, file) {
        const pipeline = StorageURL.newPipeline(new AnonymousCredential());
        const sasToken = await this.generateSasToken(containerName, false, true);
        const serviceUrl = new ServiceURL(this.blobStorageBaseUrl + sasToken, pipeline);
        const containerUrl = ContainerURL.fromServiceURL(serviceUrl, containerName);
        const blobName = `${new Date().getTime()}-${file.name}`;
        const blobUrl = BlobURL.fromContainerURL(containerUrl, blobName);
        const blockBlobUrl = BlockBlobURL.fromBlobURL(blobUrl);

        await blockBlobUrl.upload(Aborter.none, file, file.size);

        return {
            blobName,
            blobUrl: this.getBlobUrl(containerName, blobName)
        };
    }

    async generateSasToken(containerName, read = false, write = false, expiry = 600) {
        const headers = this.getRequestHeaders(this.getAccessToken());

        const query = {
            containerName,
            read,
            write,
            expiry
        }

        const url = `${this.apiBaseUrl}/sas-tokens?${queryString.stringify(query)}`;
        const response = await axios.post(url, null, { headers });

        return response.data;
    }
}
