import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        left: 0,
        right: 0,
        marginBottom: theme.spacing(2),
        zIndex: theme.zIndex.modal
    }
});

class AddressAutocomplete extends Component {
    static propTypes = {
        types: PropTypes.array,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        types: []
    };

    constructor(props) {
        super(props);

        this.options = {
            componentRestrictions: {
                country: ['au', 'nz', 'us']  // TODO: Remove country restriction when ready for global domination.
            },
            types: props.types
        };

        this.state = {
            value: props.value
        };

        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onError = this.onError.bind(this);
    }

    onChange(value) {
        this.setState({ value });
    }

    async onSelect(value) {
        this.setState({ value });

        const results = await geocodeByAddress(value);
        let address = null;

        if (results.length > 0) {
            const { place_id: googlePlaceId, formatted_address: formattedAddress } = results[0];
            const components = this.getAddressComponents(results[0]);
            const coords = await getLatLng(results[0]);
            const { lat, lng } = coords;

            address = {
                ...components,
                googlePlaceId,
                formattedAddress,
                latitude: lat,
                longitude: lng
            };
        }

        this.props.onChange(address);
    }

    onClear() {
        this.setState({ value: '' });
        this.props.onChange(null);
    }

    onError(status, clearSuggestions) {
        clearSuggestions();
    }

    getAddressComponents(result) {
        var components = (result.address_components || [])
            .reduce((acc, data) => {
                data.types.forEach(type => acc[type] = data);
                return acc;
            }, {});

        const get = (name, short = false) => {
            if (!(name in components)) return '';
            return short ? components[name].short_name : components[name].long_name;
        };

        return {
            unitNumber: get('subpremise'),
            streetNumber: get('street_number'),
            streetName: get('route'),
            suburb: get('locality') ||
                get('sublocality') ||
                get('sublocality_level_1') ||
                get('neighborhood') ||
                get('administrative_area_level_3'),
            //city: get('administrative_area_level_2', true),
            state: get('administrative_area_level_1', true),
            stateName: get('administrative_area_level_1'),
            country: get('country'),
            countryCode: get('country', true),
            postcode: get('postal_code')
        };
    }

    render() {
        const { classes, address, ...rest } = this.props;
        const { value } = this.state;

        const clearButtonAdornment = <InputAdornment position="end">
            <IconButton aria-label="Clear" onClick={this.onClear}>
                <Clear />
            </IconButton>
        </InputAdornment>;

        const renderAutocomplete = ({ getInputProps, suggestions, getSuggestionItemProps }) =>
            <Fragment>
                <TextField
                    className="location-search-input"
                    InputProps={getInputProps({
                        endAdornment: Boolean(value) ? clearButtonAdornment : null
                    })}
                    {...rest} />

                <div className={`autocomplete-dropdown-container ${classes.container}`}>
                    <Paper className={classes.paper}>
                        {suggestions.map(s =>
                            <MenuItem key={s.id} value={s.id} {...getSuggestionItemProps(s)}>
                                {s.description}
                            </MenuItem>)}
                    </Paper>
                </div>
            </Fragment>;

        return <PlacesAutocomplete
            searchOptions={this.options}
            debounce={500}
            shouldFetchSuggestions={value.length > 3}
            onError={this.onError}
            value={value}
            onChange={this.onChange}
            onSelect={this.onSelect}>
            {renderAutocomplete}
        </PlacesAutocomplete>;
    }
}

export default withStyles(styles)(AddressAutocomplete);