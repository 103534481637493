import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import AppContainer from '../../containers/AppContainer';
import ShoppingLocationContainer from '../../containers/ShoppingLocationContainer';
import { Provider, Subscribe } from 'unstated';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import ShareButtons from '../../components/ShareButtons';
import ShoppingLocationDetails from './ShoppingLocationDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFound from '../Statuses/NotFound';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import { withRouter } from 'react-router';
import { spinnerStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2) + 37, // Account for footer height.
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2) + 54 // Account for footer height (double height on xs viewport).
        }
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    title: {
        flex: 1,
        fontFamily: 'Open Sans Condensed',
        fontSize: '2.5rem',
        letterSpacing: 'unset',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: '2rem'
        }
    },
    shareContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    container: {
        display: 'flex'
    },
    ...spinnerStyle(theme)
});

class ShoppingLocation extends Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        storeSearchService: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.shoppingLocationContainer = new ShoppingLocationContainer(props.storeSearchService);
        this.onGoBack = this.onGoBack.bind(this);
    }

    state = {
        notFound: false
    };

    async componentDidMount() {
        const { slug } = this.props;

        try {
            await this.shoppingLocationContainer.load(slug);
        } catch (error) {
            if (error.response.status === 404) {
                this.setState({ notFound: true });
            }
        }
    }

    canGoBack() {
        const { location } = this.props;
        const { state } = location;

        return state != null && Boolean(state.canGoBack);
    }

    onGoBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes, storeSearchService, googleApiKey } = this.props;
        const { notFound } = this.state;

        return <div className={classes.root}>
            <Header storeSearchService={storeSearchService} />
            {!notFound && <Provider inject={[this.shoppingLocationContainer]}>
                <Subscribe to={[AppContainer, ShoppingLocationContainer]}>
                    {(ac, slc) => Boolean(slc.state.shoppingLocation) ?
                        <Fragment>
                            <Helmet>
                                <meta name="description" content={slc.state.shoppingLocation.meta.description} />
                                {Boolean(slc.state.shoppingLocation.meta.description) && <meta name="keywords" content={slc.state.shoppingLocation.meta.keywords} />}
                                <meta property="og:url" content={document.location.href} />
                                <meta property="og:title" content={slc.state.shoppingLocation.meta.title} />
                                <meta property="og:description" content={slc.state.shoppingLocation.meta.description} />
                                <title>{slc.state.shoppingLocation.meta.title}</title>
                            </Helmet>
                            <Grid container justify="center" className={classes.gridContainer}>
                                <Hidden smUp>
                                    <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.shareContainer}>
                                        <ShareButtons facebook
                                            twitter
                                            email
                                            title={`${slc.state.shoppingLocation.name} is on Mavitra.`}
                                            url={document.location.href}
                                            size={48}
                                            urlShortener={ac.shortenUrl}
                                            onShare={utms => ac.onShareShoppingLocationClicked(slc.state.shoppingLocation.id, utms)} />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.titleContainer}>
                                    <Hidden xsDown>
                                        {this.canGoBack() && <IconButton color="primary" style={{ marginRight: 8 }} onClick={this.onGoBack}>
                                            <BackIcon fontSize="large" />
                                        </IconButton>}
                                    </Hidden>
                                    <Typography variant="h1" className={classes.title}>{slc.state.shoppingLocation.name}</Typography>
                                    <Hidden xsDown>
                                        <ShareButtons facebook
                                            twitter
                                            email
                                            title={`${slc.state.shoppingLocation.name} is on Mavitra.`}
                                            url={document.location.href}
                                            size={48}
                                            urlShortener={ac.shortenUrl}
                                            onShare={utms => ac.onShareShoppingLocationClicked(slc.state.shoppingLocation.id, utms)} />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                                    <Paper className={classes.container}>
                                        <ShoppingLocationDetails
                                            shoppingLocation={slc.state.shoppingLocation}
                                            googleApiKey={googleApiKey}
                                            onOpenParentShoppingLocation={slug => this.props.history.push({
                                                pathname: `/shopping-locations/${slug}`,
                                                state: { canGoBack: true }
                                            })} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Fragment> :
                        <CircularProgress className={classes.spinner} size={90} thickness={2.4} />}
                </Subscribe>
            </Provider>}
            {notFound && <NotFound />}
            <Footer solid />
        </div>;
    }
}

export default withRouter(withStyles(styles)(ShoppingLocation));