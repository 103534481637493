import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider, Subscribe } from 'unstated';
import AppContainer from '../../containers/AppContainer';
import StoreSearchContainer from '../../containers/StoreSearchContainer';
import Header from '../../components/Header';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import Footer from '../../components/Footer';
import StoreGrid from './StoreGrid';
import FacetList from './FacetList';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SideDrawer from '../../components/SideDrawer';
import StoreDetails from './StoreDetails';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import CreateSavedSearchForm from './CreateSavedSearchForm';
import CreateAvailabilityNotificationForm from './CreateAvailabilityNotificationForm';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import CloseIcon from '@material-ui/icons/Close';
import SaveSearchIcon from '@material-ui/icons/PlaylistAdd';
import CopyLinkIcon from '@material-ui/icons/AssignmentReturn';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import ShareButtons from '../../components/ShareButtons';
import { getAddressComponents, resolveLocationName } from '../../containers/AppContainer';
import queryString from 'query-string';
import slugify from 'slugify';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import green from '@material-ui/core/colors/green';
import StoreCard from './StoreCard';
import { spinnerStyle } from '../../styles';

const Distances = [25, 50, 100, 250, 500];

const DefaultDistance = Distances[1];

const OrderBy = {
    RELEVANCE: 'relevance',
    DISTANCE: 'distance',
    RATING: 'rating'
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2) + 35, // Account for footer height.
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2) + 54 // Account for footer height (double height on xs viewport).
        }
    },
    resultsHeader: {
        display: 'flex',
        alignItems: 'center',
        '& > .searchSelect': {
            marginLeft: theme.spacing(1)
        }
    },
    filterButton: {
        marginRight: theme.spacing(1)
    },
    filtersDrawer: {
        padding: theme.spacing(2)
    },
    selectLabel: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    results: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    facets: {
        marginRight: theme.spacing(2),
        flexShrink: 0
    },
    facetList: {
        marginBottom: theme.spacing(1)
    },
    clearFilters: {
        marginBottom: theme.spacing(2)
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginRight: theme.spacing(2)
        }
    },
    actionText: {
        textTransform: 'uppercase'
    },
    actions: {
        '&:not(:empty)': {
            marginLeft: theme.spacing(3)
        }
    },
    actionButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    shareButtons: {
        marginLeft: theme.spacing(3)
    },
    success: {
        backgroundColor: green[600]
    },
    ...spinnerStyle(theme)
});

class Search extends Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
        imageSearch: PropTypes.bool.isRequired,
        trending: PropTypes.bool.isRequired,
        storeSearchService: PropTypes.object.isRequired,
        productSearchService: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired
    };

    static defaultProps = {
        imageSearch: false,
        trending: false
    };

    state = {
        searchLocationName: null,
        hasSearched: false,
        busy: false,
        drawerTitle: '',
        drawerContent: null,
        snackContent: null,
        filtersOpen: false
    };

    constructor(props) {
        super(props);

        this.storeSearchContainer = new StoreSearchContainer(props.storeSearchService, props.customerService);

        this.onSearchLocationNameResolved = this.onSearchLocationNameResolved.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onOpenNearbyStore = this.onOpenNearbyStore.bind(this);
    }

    async componentDidMount() {
        const { params, imageSearch, trending } = this.props;

        const nextState = {
            ...this.paramsToState(params),
            // Trending search flag for analytics.
            imageSearch,
            trending
        };

        this.storeSearchContainer.setState(nextState, async () => {
            this.setState({ busy: true });

            await this.storeSearchContainer.load();

            if (this.unmounted) {
                return;
            }

            this.setState({
                hasSearched: true,
                busy: false
            });
        });

        if (params.lat && params.lng) {
            // Reverse geocode current search coords to obtain search location name.
            resolveLocationName(parseFloat(params.lat),
                parseFloat(params.lng),
                this.onSearchLocationNameResolved);
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    onSearchLocationNameResolved(results, status) {
        if (this.unmounted) {
            return;
        }

        if (status === window.google.maps.GeocoderStatus.OK) {
            const addressComponents = getAddressComponents(results[0]);

            this.setState({
                searchLocationName: addressComponents.suburb || addressComponents.state || addressComponents.country
            });
        }
    }

    paramsToState(params) {
        // Convert query string params to state object for container.
        // Params are: q, brand, cat, feat, loc, tag, lat, lng, r, o.
        const makeArray = v => !Boolean(v) ? [] : typeof v === 'string' ? [v] : v;

        const state = {
            query: this.getSearchString(),
            brands: makeArray(params.brand),
            categories: makeArray(params.cat),
            features: makeArray(params.feat),
            locations: makeArray(params.loc),
            tags: makeArray(params.tag)
        };

        if ('test' in params) {
            state.test = true;
        }

        if (params.o) {
            // Only allow valid order by values. Fallback to null (relevance) if invalid value used.
            switch (params.o) {
                case OrderBy.RELEVANCE:
                case OrderBy.DISTANCE:
                case OrderBy.RATING:
                    state.orderBy = params.o;
                    break;
                default:
                    state.orderBy = null;
            }
        }

        if (params.lat && params.lng) {
            state.coords = {
                latitude: parseFloat(params.lat),
                longitude: parseFloat(params.lng)
            };

            if (params.r) {
                state.radius = parseInt(params.r);
            }
        } else {
            // We can't order by distance with no location. Reset order by in this case.
            if (params.o === OrderBy.DISTANCE) {
                state.orderBy = null;
            }
        }

        return state;
    }

    async loadMore(ssc) {
        this.setState({ busy: true });
        await ssc.loadMore();
        this.setState({ busy: false });
    }

    getSearchString() {
        return (this.props.params.q || 'Mavitra').replace(/-/g, ' ');
    }

    navigate(params, coords, imageSearch = false, trending = false) {
        if (Boolean(coords)) {
            params.lat = coords.latitude;
            params.lng = coords.longitude;
        }

        if (this.storeSearchContainer.state.test) {
            params.test = true;
        }

        const route = {
            pathname: '/search',
            search: queryString.stringify(params, { sort: false }),
            state: {
                imageSearch,
                trending
            }
        };

        this.props.history.push(route);
    }

    onSearch(query, shoppingLocation, category, coords) {
        if (!Boolean(query)) {
            return;
        }

        const { radius, orderBy } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            o: orderBy
        };

        if (Boolean(shoppingLocation)) {
            params.loc = shoppingLocation;
        }

        if (Boolean(category)) {
            params.cat = category;
        }

        if (Boolean(coords)) {
            params.r = radius;
        }

        this.navigate(params, coords);
    }

    onUseMyLocationChanged(ac) {
        if (ac.isTrackingPosition()) {
            ac.stopTrackingPosition();
        } else {
            ac.startTrackingPosition();
        }
    }

    onChangeDistance(radius) {
        // Keep all current search params except radius.
        const { query, locations, categories, brands, features, tags, coords, orderBy, imageSearch, trending } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            loc: locations,
            cat: categories,
            brand: brands,
            feat: features,
            tag: tags,
            r: radius,
            o: orderBy
        };

        this.navigate(params, coords, imageSearch, trending);
    }

    onOrderResults(orderBy) {
        // Keep all current search params except order by.
        const { query, locations, categories, brands, features, tags, coords, radius, imageSearch, trending } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            loc: locations,
            cat: categories,
            brand: brands,
            feat: features,
            tag: tags,
            o: orderBy
        };

        if (Boolean(coords)) {
            params.r = radius;
        }

        this.navigate(params, coords, imageSearch, trending);
    }

    onFacetClick(key, value) {
        const { query, locations, categories, brands, features, tags, coords, radius, orderBy, imageSearch, trending } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            loc: locations,
            cat: categories,
            brand: brands,
            feat: features,
            tag: tags,
            o: orderBy
        };

        if (Boolean(coords)) {
            params.r = radius;
        }

        const values = params[key];
        const index = values.indexOf(value);

        // Add facet if not already selected, otherwise remove it.
        if (index >= 0) {
            values.splice(index, 1);
        }
        else {
            values.push(value);
        }

        this.navigate(params, coords, imageSearch, trending);
    }

    onClearFilters() {
        // Keep everything except filters.
        const { query, coords, radius, orderBy, imageSearch, trending } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            r: radius,
            o: orderBy
        };

        this.navigate(params, coords, imageSearch, trending);
    }

    expandSearchArea(ssc) {
        const index = Distances.indexOf(ssc.state.radius);
        this.onChangeDistance(Distances[index + 1]);
    }

    forgetMyLocation(ac) {
        ac.stopTrackingPosition();

        const { query, locations, categories, brands, features, tags, orderBy, imageSearch, trending } = this.storeSearchContainer.state;

        const params = {
            q: slugify(query),
            loc: locations,
            cat: categories,
            brand: brands,
            feat: features,
            tag: tags,
            o: orderBy
        };

        this.navigate(params, null, imageSearch, trending);
    }

    showStoreDetails(ac, store, query = null) {
        const { storeSearchService, productSearchService, googleApiKey, width } = this.props;
        const isMobile = isWidthDown('xs', width);

        const navigate = (page, slug, q) => this.props.history.push({
            pathname: `/${page}/${slug}`,
            search: Boolean(q) ? queryString.stringify({ q }) : null,
            state: { canGoBack: true }
        });

        const openStore = slug => navigate('stores', slug, query);
        const openShoppingLocation = slug => navigate('shopping-locations', slug, null);

        if (isMobile) {
            // Don't use drawer on mobile as it interferes with navigation.
            // Just go straight to the store details page.
            openStore(store.slug);
            return;
        }

        this.openDrawer(store.name, <StoreDetails
            storeId={store.id}
            query={query}
            storeSearchService={storeSearchService}
            productSearchService={productSearchService}
            googleApiKey={googleApiKey}
            onOpenStore={openStore}
            onOpenShoppingLocation={openShoppingLocation}
            onOpenNearbyStore={(id, slug) => this.onOpenNearbyStore(ac, store.id, id, slug)} />);
    }

    onSaveSearch(ac, ssc) {
        this.openDrawer('Save this search', <CreateSavedSearchForm
            locationName={this.state.searchLocationName}
            hasLocation={ssc.hasLocation()}
            onSave={form => this.createSavedSearch(ac, ssc, form)}
            onCancel={() => this.closeDrawer()} />);
    }

    onCopySearchLinkToClipboard() {
        navigator.clipboard.writeText(document.location.href);
        alert('Search link has been copied to your clipboard.');
    }

    async createSavedSearch(ac, ssc, form) {
        const customerId = ac.getClaim('sub');
        const created = await ssc.createSavedSearch(customerId, form);

        if (created) {
            this.closeDrawer();
            this.setSnack(<div>Search saved. <Link href="#" color="inherit" underline="always" onClick={() => this.props.history.push('/my-profile/saved-searches')}>View all saved searches.</Link></div>);
        }
    }

    onCreateAvailabilityNotification(ac, ssc) {
        this.openDrawer('Notify me when available', <CreateAvailabilityNotificationForm
            emailAddress={ac.getClaim('email', '')}
            onSave={form => this.createAvailabilityNotification(ssc, form)}
            onCancel={() => this.closeDrawer()} />);
    }

    async createAvailabilityNotification(ssc, form) {
        const created = await ssc.createAvailabilityNotification(form);

        if (created) {
            this.closeDrawer();
            this.setSnack("Thanks, we'll let you know.");
        }
    }

    onOpenNearbyStore(ac, thisStoreId, nearbyStoreId, nearbyStoreSlug) {
        ac.onNearbyStoreClicked(thisStoreId, nearbyStoreId);

        this.props.history.push({
            pathname: `/stores/${nearbyStoreSlug}`,
            state: { canGoBack: true }
        });
    }

    toggleFiltersDrawer(open) {
        this.setState({ filtersOpen: open });
    }

    openDrawer(drawerTitle, drawerContent) {
        this.setState({
            drawerTitle,
            drawerContent
        });
    }

    closeDrawer() {
        this.setState({
            drawerTitle: '',
            drawerContent: null
        });
    }

    setSnack(content = null) {
        this.setState({ snackContent: content });
    }

    handleCloseSnack = () => this.setSnack();

    renderResultsHeader(ssc, classes, nearText) {
        const { params } = this.props;

        return <div className={classes.resultsHeader}>
            {ssc.state.totalCount === 0 && <Typography align="center" variant="body1" style={{ flex: 1 }}>
                Sorry, none of our stores{nearText} stock what you're after.
            </Typography>}
            {ssc.state.totalCount > 0 && <Fragment>
                <Hidden smDown>
                    <Typography variant="body1" style={{ flex: 1 }}>
                        We found {ssc.state.totalCount} store{ssc.state.totalCount > 1 && 's'}{nearText}...
                    </Typography>
                </Hidden>
                <Hidden smUp>
                    <IconButton className={classes.filterButton} onClick={() => this.toggleFiltersDrawer(true)} >
                        <FilterListIcon />
                    </IconButton>
                    <div style={{ flex: 1 }} />
                </Hidden>
                <Hidden mdUp xsDown>
                    <Button className={classes.filterButton} onClick={() => this.toggleFiltersDrawer(true)} >
                        <FilterListIcon style={{ marginRight: 8 }} />
                        Show filters
                    </Button>
                    <div style={{ flex: 1 }} />
                </Hidden>
            </Fragment>}
            {ssc.state.totalCount > 0 && Boolean(params.lat && params.lng) && this.renderDistanceList(ssc.state.radius || DefaultDistance)}
            {ssc.state.totalCount > 1 && <Fragment>
                {this.renderOrderList(ssc.state.orderBy || OrderBy.RELEVANCE, Boolean(ssc.state.coords))}
                <Hidden smDown>
                    <Subscribe to={[AppContainer]}>
                        {ac => <Fragment>
                            <div className={classes.actions}>
                                {ac.hasToken() && <Fab className={classes.actionButton}
                                    color="secondary"
                                    title="Save this search"
                                    size="medium"
                                    onClick={() => this.onSaveSearch(ac, ssc)}>
                                    <SaveSearchIcon />
                                </Fab>}
                                {Boolean(navigator.clipboard) && Boolean(ac.getClaim('admin', false)) && <Fab className={classes.actionButton}
                                    color="secondary"
                                    title="Copy search link to clipboard"
                                    size="medium"
                                    onClick={() => this.onCopySearchLinkToClipboard()}>
                                    <CopyLinkIcon />
                                </Fab>}
                            </div>
                            <div className={classes.shareButtons}>
                                <ShareButtons facebook
                                    twitter
                                    email
                                    url={document.location.href}
                                    sho
                                    size={48}
                                    urlShortener={ac.shortenUrl}
                                    onShare={utms => ac.onShareSearchClicked(utms)} />
                            </div>
                        </Fragment>}
                    </Subscribe>
                </Hidden>
            </Fragment>}
        </div>;
    }

    renderDistanceList(value) {
        return <TextField
            className="searchSelect"
            label="Within"
            select
            value={value}
            InputProps={{
                disableUnderline: true
            }}
            onChange={e => this.onChangeDistance(e.target.value)}>
            {Distances.map(d => <MenuItem key={d} value={d}>{d} km</MenuItem>)}
        </TextField>;
    }

    renderOrderList(value, hasLocation) {
        return <TextField
            className="searchSelect"
            label="Sort by"
            select
            value={value}
            InputProps={{
                disableUnderline: true
            }}
            onChange={e => this.onOrderResults(e.target.value)}>
            <MenuItem key={OrderBy.RELEVANCE} value={OrderBy.RELEVANCE}>Best match</MenuItem>
            {hasLocation && <MenuItem key={OrderBy.DISTANCE} value={OrderBy.DISTANCE}>Distance from me</MenuItem>}
            <MenuItem key={OrderBy.RATING} value={OrderBy.RATING}>Average rating</MenuItem>
        </TextField>;
    }

    renderFacetSection(title, key, values, selected, classes) {
        if (Object.keys(values).length === 0) {
            return null;
        }

        return <Fragment>
            <Typography variant="overline">{title}</Typography>
            <FacetList facets={values}
                selected={selected}
                className={classes.facetList}
                onClick={value => this.onFacetClick(key, value)} />
        </Fragment>;
    }

    renderFacets(ssc, classes) {
        const { brands, categories, features, locations, tags } = ssc.state;
        const hasFilter = [...brands, ...categories, ...features, ...locations, ...tags].length > 0;

        return <Fragment>
            {hasFilter && <Button fullWidth size="small" color="primary" className={classes.clearFilters} onClick={this.onClearFilters}>
                Clear filters
            </Button>}
            {this.renderFacetSection('Locations', 'loc', ssc.state.facets.shoppingLocations, ssc.state.locations, classes)}
            {this.renderFacetSection('Categories', 'cat', ssc.state.facets.categories, ssc.state.categories, classes)}
            {this.renderFacetSection('Brands', 'brand', ssc.state.facets.brands, ssc.state.brands, classes)}
            {this.renderFacetSection('Features', 'feat', ssc.state.facets.features, ssc.state.features, classes)}
            {this.renderFacetSection('Tags', 'tag', ssc.state.facets.tags, ssc.state.tags, classes)}
        </Fragment>;
    }

    render() {
        const { classes, width, params, history, storeSearchService } = this.props;
        const { hasSearched, busy, drawerTitle, drawerContent, snackContent, filtersOpen, searchLocationName } = this.state;
        const { radius } = this.storeSearchContainer.state;
        let nearText = null;

        if (Boolean(searchLocationName)) {
            nearText = ` within ${radius} km of ${searchLocationName}`;
        }

        return <div className={classes.root}>
            <Header searchText={this.getSearchString()}
                storeSearchService={storeSearchService}
                onSearch={(q, sl, cat, pos) => this.onSearch(q, sl, cat, pos)} />
            <Provider inject={[this.storeSearchContainer]}>
                {hasSearched && <Subscribe to={[StoreSearchContainer]}>
                    {ssc => <Fragment>
                        <Grid container justify="center" spacing={2}>
                            <Grid item xs={12} lg={10}>
                                {this.renderResultsHeader(ssc, classes, nearText)}
                            </Grid>
                            {ssc.state.totalCount === 0 && <Grid item xs={12} lg={10} className={classes.actionContainer}>
                                <Subscribe to={[AppContainer]}>
                                    {ac => <Button color="primary" fullWidth={isWidthDown('xs', width)} onClick={() => this.onCreateAvailabilityNotification(ac, ssc)}>Let me know when they do</Button>}
                                </Subscribe>
                            </Grid>}
                            <Grid item xs={12} lg={10}>
                                <Divider />
                            </Grid>
                            {ssc.state.expandedStores.length > 0 && <Fragment>
                                <Grid item xs={12} lg={10}>
                                    <Typography align="center" variant="body1">
                                        Here's some stores we found further away that you might be interested in...
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={10}>
                                    <Grid container justify="center" spacing={2}>
                                        {ssc.state.expandedStores.map(s => <Grid key={s.id} item xs={12} sm={6} md={4} xl={3}>
                                            <Subscribe to={[AppContainer]}>
                                                {ac => <StoreCard store={s} onSelect={s => this.showStoreDetails(ac, s, ssc.state.query)} />}
                                            </Subscribe>
                                        </Grid>)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={10} className={classes.actionContainer}>
                                    {/* Expand radius to maximum button */}
                                    <Button color="primary"
                                        fullWidth={isWidthDown('xs', width)}
                                        onClick={() => this.onChangeDistance(Distances[Distances.length - 1])}>
                                        Show me more
                                        </Button>
                                </Grid>
                                <Grid item xs={12} lg={10}>
                                    <Divider />
                                </Grid>
                            </Fragment>}
                            {ssc.state.totalCount === 0 && <Grid item xs={12}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {params.lat && params.lng && ssc.state.radius !== Distances[Distances.length - 1] &&
                                        <div className={classes.actionContainer}>
                                            <IconButton onClick={() => this.expandSearchArea(ssc)}>
                                                <ZoomOutMapIcon />
                                            </IconButton>
                                            <Typography align="center" variant="caption" className={classes.actionText}>Expand search radius</Typography>
                                        </div>}
                                    {params.lat && params.lng && <Subscribe to={[AppContainer]}>
                                        {ac => <div className={classes.actionContainer}>
                                            <IconButton onClick={() => this.forgetMyLocation(ac)}>
                                                <LocationOffIcon />
                                            </IconButton>
                                            <Typography align="center" variant="caption" className={classes.actionText}>Forget my location</Typography>
                                        </div>}
                                    </Subscribe>}
                                    <div className={classes.actionContainer}>
                                        <IconButton onClick={() => history.push('/')}>
                                            <SearchIcon />
                                        </IconButton>
                                        <Typography align="center" variant="caption" className={classes.actionText}>Start a new search</Typography>
                                    </div>
                                </div>
                            </Grid>}
                            <Hidden mdUp>
                                {ssc.state.totalCount > 0 && <Grid item xs={12}>
                                    <Typography variant="body1" style={{ flex: 1 }}>
                                        {ssc.state.totalCount} store{ssc.state.totalCount > 1 && 's'} found{nearText}...
                                        </Typography>
                                </Grid>}
                                {ssc.state.totalCount > 1 && <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Subscribe to={[AppContainer]}>
                                        {ac => <Fragment>
                                            <div style={{ flex: 1 }}>
                                                {ac.hasToken() && <Fab className={classes.actionButton}
                                                    color="secondary"
                                                    title="Save this search"
                                                    size="medium"
                                                    onClick={() => this.onSaveSearch(ac, ssc)}>
                                                    <SaveSearchIcon />
                                                </Fab>}
                                                {Boolean(navigator.clipboard) && Boolean(ac.getClaim('admin', false)) && <Fab className={classes.actionButton}
                                                    color="secondary"
                                                    title="Copy search link to clipboard"
                                                    size="medium"
                                                    onClick={() => this.onCopySearchLinkToClipboard()}>
                                                    <CopyLinkIcon />
                                                </Fab>}
                                            </div>
                                            <div className={classes.shareButtons}>
                                                <ShareButtons facebook
                                                    twitter
                                                    email
                                                    url={document.location.href}
                                                    size={48}
                                                    urlShortener={ac.shortenUrl}
                                                    onShare={utms => ac.onShareSearchClicked(utms)} />
                                            </div>
                                        </Fragment>}
                                    </Subscribe>
                                </Grid>}
                            </Hidden>
                            {ssc.state.totalCount > 0 && <Grid item xs={12} lg={10} className={classes.results}>
                                <Hidden smDown>
                                    <div className={classes.facets}>
                                        {this.renderFacets(ssc, classes)}
                                    </div>
                                </Hidden>
                                <Subscribe to={[AppContainer]}>
                                    {ac => <StoreGrid onSelect={s => this.showStoreDetails(ac, s, ssc.state.query)} />}
                                </Subscribe>
                            </Grid>}
                            {ssc.hasMore() && <Grid item xs={12} sm={6} md={3} lg={3}>
                                {/* Next page button */}
                                <Button color="secondary"
                                    fullWidth
                                    className={classes.loadMore}
                                    onClick={() => this.loadMore(ssc)}>
                                    Show me more
                                    </Button>
                            </Grid>}
                        </Grid>
                        <Hidden mdUp>
                            <SideDrawer title="Store filters"
                                swipeable
                                autoFullWidth={false}
                                open={filtersOpen}
                                onClose={() => this.toggleFiltersDrawer(false)}>
                                <div className={classes.filtersDrawer}>
                                    {this.renderFacets(ssc, classes)}
                                </div>
                            </SideDrawer>
                        </Hidden>
                    </Fragment>}
                </Subscribe>}
            </Provider>
            <SideDrawer title={drawerTitle}
                anchor="right"
                open={Boolean(drawerContent)}
                onClose={this.closeDrawer}>
                {drawerContent}
            </SideDrawer>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={Boolean(snackContent)}
                autoHideDuration={5000}
                onClose={this.handleCloseSnack}
                ContentProps={{ 'aria-describedby': 'message-id' }}>
                <SnackbarContent
                    className={classes.success}
                    message={<span id="message-id">{snackContent}</span>}
                    action={[<IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseSnack}>
                        <CloseIcon />
                    </IconButton>]}
                />
            </Snackbar>
            {busy && <CircularProgress className={classes.spinner} size={90} thickness={2.4} />}
            <Footer solid />
        </div>;
    }
}

export default withRouter(withStyles(styles)(withWidth()(Search)));