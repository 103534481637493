import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StockStatus from './StockStatus';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        width: '100%',
        display: 'flex'
    },
    cardContent: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(1)
    },
    image: {
        maxWidth: 80,
        maxHeight: 80
    }
}));

function ProductCard({ store, product, onSelect }) {
    const classes = useStyles();

    return <Card className={classes.card}>
        <CardActionArea
            component="a"
            href={`${window.location.origin}/stores/${store.storeSlug}/products/${product.slug}`}
            onClick={e => {
                e.preventDefault();
                onSelect(product);
            }}>
            <CardContent classes={{ root: classes.cardContent }}>
                <div className={classes.imageContainer}>
                    <img className={classes.image} src={product.heroImageUrl || '/images/no-image.png'} alt={product.name} title={product.name} />
                </div>
                <Typography gutterBottom noWrap align="center" display="block">
                    {product.name}
                </Typography>
                <StockStatus stockStatus={product.stockStatus} />
            </CardContent>
        </CardActionArea>
    </Card>;
}

ProductCard.propTypes = {
    store: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired
}

export default ProductCard;