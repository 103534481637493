import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    }
});

class SavedSearches extends Component {
    static propTypes = {
        savedSearches: PropTypes.array.isRequired,
        onRun: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired
    };

    render() {
        const { classes, savedSearches, onRun, onRemove } = this.props;

        return <div className={classes.root}>
            {savedSearches.length === 0 && <Typography className={classes.banner} variant="caption">
                <InfoIcon style={{ marginRight: 8 }} />
                You don't have any saved searches yet. You can save a search from the main page after you've searched for something.
            </Typography>}
            <Paper>
                <List disablePadding>
                    {savedSearches.map((ss, index) =>
                        <ListItem key={ss.id} button divider={index < savedSearches.length - 1} onClick={() => onRun(ss)}>
                            <ListItemText primary={ss.name} secondary={ss.description} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => onRemove(ss)}>
                                    <DeleteIcon />
                                </IconButton>
                                {/* <OverflowMenu onRun={() => onRun(ss)} onDelete={() => onRemove(ss)} /> */}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                </List>
            </Paper>
        </div>;
    }
}

/*
class OverflowMenu extends Component {
    static propTypes = {
        onRun: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleRun = () => {
        this.props.onRun();
        this.setState({ anchorEl: null });
    };

    handleDelete = () => {
        this.props.onDelete();
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return <div>
            <IconButton onClick={this.handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={this.handleClose}>
                <MenuItem onClick={this.handleRun}>
                    Run
                </MenuItem>
                <MenuItem onClick={this.handleDelete}>
                    Delete
                </MenuItem>
            </Menu>
        </div>;
    }
}
*/
export default withStyles(styles)(SavedSearches);