import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const styles = theme => ({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        flex: 1
    },
    gridTile: {
        cursor: 'pointer'
    },
    actions: {
        justifyContent: 'center'
    }
});

class SelectImageDialog extends React.Component {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        open: PropTypes.bool.isRequired,
        images: PropTypes.array.isRequired,
        onSelectImage: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    };

    render() {
        const { classes, fullScreen, width, open, images, onSelectImage, onClose } = this.props;

        return <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title" disableTypography>
                <Typography align="center" display="block">Which image best describes what you're looking for?</Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <GridList cellHeight={120} className={classes.gridList} spacing={8} cols={isWidthUp('sm', width) ? 3 : 2}>
                    {images.map(img => (
                        <GridListTile key={img.imageUrl} className={classes.gridTile} onClick={() => onSelectImage(img)}>
                            <img src={img.imageUrl} alt={img.query} title={img.query} />
                        </GridListTile>
                    ))}
                </GridList>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

export default withStyles(styles)(withMobileDialog()(withWidth()(SelectImageDialog)));
