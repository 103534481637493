import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    },
    invert: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    transparent: {
        backgroundColor: 'transparent'
    },
    listTitle: {
        display: 'flex',
        flexDirection: 'column'
    },
    details: {
        flexDirection: 'column',
        padding: theme.spacing(0.5)
    },
    actions: {
        padding: theme.spacing(1)
    }
});

class ShoppingLists extends Component {
    static propTypes = {
        shoppingLists: PropTypes.array.isRequired,
        onAddItem: PropTypes.func.isRequired,
        onEditItem: PropTypes.func.isRequired,
        onRemoveItem: PropTypes.func.isRequired,
        onEditShoppingList: PropTypes.func.isRequired,
        onDeleteShoppingList: PropTypes.func.isRequired
    };

    state = {
        expandedListId: null
    };

    handleListChanged = listId => (event, expanded) => {
        this.setState({ expandedListId: expanded ? listId : null });
    }

    render() {
        const { classes, shoppingLists } = this.props;
        const { expandedListId } = this.state;

        return <div className={classes.root}>
            {shoppingLists.length === 0 && <Typography className={classes.banner} variant="caption">
                <InfoIcon style={{ marginRight: 8 }} />
                You don't have any shopping lists at the moment. Click the + button below to create one.
            </Typography>}
            {shoppingLists.map(sl => <ExpansionPanel
                key={sl.id}
                expanded={expandedListId === sl.id}
                onChange={this.handleListChanged(sl.id)}>
                <ExpansionPanelSummary classes={{ content: classes.transparent, expanded: classes.invert, expandIcon: classes.transparent }} expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.listTitle}>
                        <Typography color="inherit" variant="subtitle1">{`${sl.name}${sl.items.length === 0 ? ' (empty)' : ''}`}</Typography>
                    </div>
                </ExpansionPanelSummary>
                {sl.items.length > 0 &&
                    <div>
                        <Divider />
                        <ExpansionPanelDetails classes={{ root: classes.details }}>
                            <List disablePadding>
                                {sl.items.map((item, index) =>
                                    <ListItem key={index}>
                                        <ListItemText primary={item.name} secondary={item.description} />
                                        <ListItemSecondaryAction>
                                            <OverflowMenu
                                                onEdit={() => this.props.onEditItem(sl, item)}
                                                onDelete={() => this.props.onRemoveItem(sl, item)} />
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                            </List>
                        </ExpansionPanelDetails>
                    </div>}
                <Divider />
                <ExpansionPanelActions classes={{ root: classes.actions }}>
                    <IconButton classes={{ root: classes.iconButton }}
                        onClick={() => this.props.onAddItem(sl)}>
                        <AddIcon />
                    </IconButton>
                    <IconButton classes={{ root: classes.iconButton }}
                        onClick={() => this.props.onEditShoppingList(sl)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton classes={{ root: classes.iconButton }}
                        onClick={() => this.props.onDeleteShoppingList(sl)}>
                        <DeleteIcon />
                    </IconButton>
                </ExpansionPanelActions>
            </ExpansionPanel>)}
        </div>;
    }
}

class OverflowMenu extends Component {
    static propTypes = {
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = () => {
        this.props.onEdit();
        this.setState({ anchorEl: null });
    };

    handleDelete = () => {
        this.props.onDelete();
        this.setState({ anchorEl: null });
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return <div>
            <IconButton onClick={this.handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={this.handleClose}>
                <MenuItem onClick={this.handleEdit}>
                    Edit
                </MenuItem>
                <MenuItem onClick={this.handleDelete}>
                    Delete
                </MenuItem>
            </Menu>
        </div>;
    }
}

export default withStyles(styles)(ShoppingLists);