import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        // Promote the list into its own layer on Chrome. This cost memory but helps keep high FPS.
        transform: 'translateZ(0)',
        flexGrow: 1
    },
    gridListTile: {
        cursor: 'pointer'
    }
});

class ImageGrid extends Component {
    static propTypes = {
        images: PropTypes.array.isRequired,
        wrap: PropTypes.bool.isRequired,
        onSelect: PropTypes.func
    };

    static defaultProps = {
        wrap: false
    };

    onSelect(image, index) {
        if (Boolean(this.props.onSelect)) {
            this.props.onSelect(image, index);
        }
    }

    render() {
        const { classes, images, wrap, ...rest } = this.props;

        const gridListStyles = {
            flexWrap: wrap ? 'wrap' : 'nowrap'
        };

        return <div className={classes.root}>
            <GridList className={classes.gridList} cols={2.5} spacing={8} style={gridListStyles} {...rest}>
                {images.map((image, index) => (
                    <GridListTile key={index} className={classes.gridListTile} onClick={() => this.onSelect(image, index)}>
                        <img src={image.imageUrl} alt={image.description} title={image.description} />
                    </GridListTile>
                ))}
            </GridList>
        </div>;
    }
}

export default withStyles(styles)(ImageGrid);