import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import yellow from '@material-ui/core/colors/yellow';

const styles = theme => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: theme.spacing(3)
    },
    avatar: {
        width: 60,
        height: 60,
        [theme.breakpoints.down('sm')]: {
            width: 45,
            height: 45
        }
    },
    defaultAvatar: {
        backgroundColor: theme.palette.primary.main
    },
    adminBanner: {
        backgroundColor: yellow[500],
        padding: theme.spacing(1),
        width: '100%'
    }
});

class UserMenu extends Component {
    static propTypes = {
        fullName: PropTypes.string.isRequired,
        emailAddress: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        isAdmin: PropTypes.bool.isRequired,
        onShowAccount: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired
    };

    static defaultProps = {
        isAdmin: false,
    };

    state = {
        anchorEl: null
    };

    handleMenu(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleRequestClose() {
        this.setState({ anchorEl: null });
    }

    onMyAccount() {
        this.props.onShowAccount();
        this.handleRequestClose();
    }

    onSignOut() {
        this.props.onSignOut();
        this.handleRequestClose();
    }

    render() {
        const { classes, fullName, emailAddress, imageUrl, isAdmin } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const initials = fullName.split(' ').map(s => s[0]).join('');

        const avatar = <Avatar className={classes.avatar}
            classes={{ colorDefault: classes.defaultAvatar }}
            src={imageUrl}
            alt={fullName}
            title={fullName}>
            {!Boolean(imageUrl) && initials}
        </Avatar>;

        const header = <div className={classes.header}>
            <div className={classes.userInfo}>
                <Typography variant="body2">{fullName}</Typography>
                <Typography variant="body2">{emailAddress}</Typography>
            </div>
            {avatar}
        </div>;

        const menu = <List>
            {!isAdmin &&
                <ListItem button onClick={() => this.onMyAccount()}>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="My profile" />
                </ListItem>}

            <ListItem button onClick={() => this.onSignOut()}>
                <ListItemIcon>
                    <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
            </ListItem>
        </List>;

        return <Fragment>
            <IconButton onClick={e => this.handleMenu(e)}>
                {avatar}
            </IconButton>
            <Popover anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                marginThreshold={8}
                open={open}
                onClose={() => this.handleRequestClose()}>
                {header}
                {isAdmin && <div className={classes.adminBanner}>
                    <Typography align="center" display="block" variant="button">Administrator</Typography>
                </div>}
                {!isAdmin && <Divider />}
                {menu}
            </Popover>
        </Fragment>;
    }
}

export default withRouter(withStyles(styles)(UserMenu));