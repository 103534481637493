import { Container } from 'unstated';

export default class MyProfileContainer extends Container {
    constructor(customerService) {
        super();

        this.customerService = customerService;
    }

    state = {
        customer: null,
        shoppingLists: [],
        favouriteStores: [],
        savedSearches: [],
        notificationSettings: {
            notificationsEnabled: true
        }
    };

    async load(customerId) {
        const response = await this.customerService.getById(customerId);

        if (response.ok) {
            this.setState({ customer: response.data },
                async () => await Promise.all([
                    this.loadFavouriteStores(),
                    this.loadShoppingLists(),
                    this.loadSavedSearches()
                ]));
        }

        return response.ok;
    }

    async update(changes) {
        const { customer } = this.state;
        const response = await this.customerService.update(customer.id, {
            ...changes,
            timestamp: customer.timestamp
        });

        if (response.ok) {
            this.setState({ customer: response.data });
        }

        return response.ok;
    }

    async loadFavouriteStores() {
        const { customer } = this.state;
        const response = await this.customerService.getFavouriteStores(customer.id);

        if (response.ok) {
            this.setState({ favouriteStores: response.data });
        }

        return response.ok;
    }

    async removeFavouriteStore(favouriteStoreId) {
        const { customer } = this.state;
        const response = await this.customerService.removeFavouriteStore(customer.id, favouriteStoreId);

        if (response.ok) {
            const favouriteStores = this.state.favouriteStores.slice();
            const index = favouriteStores.findIndex(fs => fs.id === favouriteStoreId);

            favouriteStores.splice(index, 1);

            this.setState({ favouriteStores });
        }

        return response.ok;
    }

    async loadShoppingLists() {
        const { customer } = this.state;
        const response = await this.customerService.getShoppingLists(customer.id);

        if (response.ok) {
            this.setState({ shoppingLists: response.data });
        }

        return response.ok;
    }

    async createShoppingList(shoppingList) {
        const { customer } = this.state;
        const response = await this.customerService.createShoppingList(customer.id, shoppingList);

        if (response.ok) {
            await this.loadShoppingLists();
        }

        return response.ok;
    }

    async updateShoppingList(shoppingListId, changes) {
        const { customer } = this.state;
        const shoppingLists = this.state.shoppingLists.slice();
        const shoppingList = shoppingLists.find(sl => sl.id === shoppingListId);

        const response = await this.customerService.updateShoppingList(customer.id,
            shoppingListId, {
                ...changes,
                timestamp: shoppingList.timestamp
            });

        if (response.ok) {
            await this.loadShoppingLists();
        }

        return response.ok;
    }

    async removeShoppingList(shoppingListId) {
        const { customer } = this.state;
        const response = await this.customerService.removeShoppingList(customer.id, shoppingListId);

        if (response.ok) {
            const shoppingLists = this.state.shoppingLists.slice();
            const index = shoppingLists.findIndex(sl => sl.id === shoppingListId);

            shoppingLists.splice(index, 1);

            this.setState({ shoppingLists });
        }

        return response.ok;
    }

    async addShoppingListItem(shoppingListId, item) {
        const { customer } = this.state;
        const response = await this.customerService.addShoppingListItem(customer.id, shoppingListId, item);

        if (response.ok) {
            await this.loadShoppingLists();
        }

        return response.ok;
    }

    async updateShoppingListItem(shoppingListId, shoppingListItemId, changes) {
        const { customer } = this.state;
        const shoppingLists = this.state.shoppingLists.slice();
        const shoppingList = shoppingLists.find(sl => sl.id === shoppingListId);
        const shoppingListItem = shoppingList.items.find(sli => sli.id === shoppingListItemId);

        const response = await this.customerService.updateShoppingListItem(customer.id,
            shoppingListId,
            shoppingListItemId, {
                ...changes,
                timestamp: shoppingListItem.timestamp
            });

        if (response.ok) {
            const index = shoppingList.items.indexOf(shoppingListItem);
            shoppingList.items.splice(index, 1, response.data);

            this.setState({ shoppingLists });
        }

        return response.ok;
    }

    async removeShoppingListItem(shoppingListId, shoppingListItemId) {
        const { customer } = this.state;
        const response = await this.customerService.removeShoppingListItem(customer.id, shoppingListId, shoppingListItemId);

        if (response.ok) {
            const shoppingLists = this.state.shoppingLists.slice();
            const shoppingList = shoppingLists.find(sl => sl.id === shoppingListId);
            const index = shoppingList.items.findIndex(sli => sli.id === shoppingListItemId);

            shoppingList.items.splice(index, 1);

            this.setState({ shoppingLists });
        }
    }

    async loadSavedSearches() {
        const { customer } = this.state;
        const response = await this.customerService.getSavedSearches(customer.id);

        if (response.ok) {
            this.setState({ savedSearches: response.data });
        }

        return response.ok;
    }

    async createSavedSearch(savedSearch) {
        const { customer } = this.state;
        const response = await this.customerService.createSavedSearch(customer.id, savedSearch);

        if (response.ok) {
            await this.loadSavedSearches();
        }

        return response.ok;
    }

    async removeSavedSearch(savedSearchId) {
        const { customer } = this.state;
        const response = await this.customerService.removeSavedSearch(customer.id, savedSearchId);

        if (response.ok) {
            const savedSearches = this.state.savedSearches.slice();
            const index = savedSearches.findIndex(ss => ss.id === savedSearchId);

            savedSearches.splice(index, 1);

            this.setState({ savedSearches });
        }

        return response.ok;
    }

    async updateNotificationsEnabled(notificationsEnabled) {
        const { customer } = this.state;
        const response = await this.customerService.update(customer.id, {
            notificationsEnabled,
            timestamp: customer.timestamp
        });

        if (response.ok) {
            this.setState({ customer: response.data });
        }

        return response.ok;
    }
}