import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = theme => ({
    gridList: {
        width: '100%'
    },
    icon: {
        color: theme.palette.common.white,
    },
    banner: {
        display: 'flex',
        alignItems: 'center'
    },
    tile: {
        cursor: 'pointer'
    }
});

class FavouriteStoreList extends Component {
    static propTypes = {
        stores: PropTypes.array.isRequired,
        onSelectStore: PropTypes.func.isRequired,
        onRemoveStoreFromFavourites: PropTypes.func.isRequired
    };

    onRemoveStoreFromFavourites(e, store) {
        e.stopPropagation(); // Stop bubbling event so is doesn't fire onSelectStore too.
        this.props.onRemoveStoreFromFavourites(store);
    }

    render() {
        const { classes, width, stores, onSelectStore } = this.props;

        // Start with a default value of 4 columns then scale down as the viewport narrows.
        let cols = 4;

        if (isWidthDown('md', width)) cols--;
        if (isWidthDown('sm', width)) cols--;
        if (isWidthDown('xs', width)) cols--;

        return <div className={classes.root}>
            {stores.length === 0 && <Typography className={classes.banner} variant="caption">
                <InfoIcon style={{ marginRight: 8 }} />
                You don't have any favourite stores yet. You can add them from the main page after you've searched for something.
            </Typography>}
            <GridList cellHeight={180} className={classes.gridList} cols={cols} spacing={8}>
                {stores.map(s => (
                    <GridListTile key={s.id} className={classes.tile} onClick={() => onSelectStore(s)}>
                        <img src={s.logoImageUrl || '/images/no-image.png'} alt={`${s.storeName} logo`} title={`${s.storeName} logo`} />
                        <GridListTileBar
                            title={s.storeName}
                            actionIcon={
                                <IconButton className={classes.icon} onClick={e => this.onRemoveStoreFromFavourites(e, s)}>
                                    <DeleteIcon />
                                </IconButton>
                            } />
                    </GridListTile>))}
            </GridList>
        </div>;
    }
}

export default withStyles(styles)(withWidth()(FavouriteStoreList));