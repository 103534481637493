import axios from 'axios';
import merge from 'deepmerge';
import baseConfig from '../config.base.json';

export default class ConfigService {
    load(url) {
        return new Promise((res, rej) => {
            axios.get(url)
                .then(response => res(merge(baseConfig, response.data)))
                .catch(rej);
        });
    }
}