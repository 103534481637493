import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class ProductSearchService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async getSuggestions(storeId, partial) {
        const url = `${this.apiBaseUrl}stores/${storeId}/search/suggestions`;
        const headers = this.getRequestHeaders(this.getAccessToken());
        const query = { partial };
        const response = await axios.get(url + '?' + queryString.stringify(query), { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }

    async search(storeId, query, brands, tags, orderBy, pageNumber, pageSize, imageSearch, trending, test = false) {
        const url = `${this.apiBaseUrl}/stores/${storeId}/search`;
        const headers = this.getRequestHeaders(this.getAccessToken());

        const request = {
            query,
            brands,
            tags,
            orderBy,
            pageNumber,
            pageSize
        };

        if (imageSearch) {
            request.imageSearch = true;
        }

        if (trending) {
            request.trending = true;
        }

        if (test) {
            request.test = true;
        }

        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });
        const { data } = response;

        // Search ID is a server-generated key that uniquely identifies this search.
        const searchId = response.headers['x-search-id'];

        if (searchId) {
            // Pass through the search ID on all subsequent requests to correlate user events.
            axios.defaults.headers.common['X-Search-Id'] = searchId;
        }
        

        return {
            ok: response.status === 200,
            products: data.products,
            facets: data.facets,
            totalCount: parseInt(response.headers['x-total-count'], 10)
        };
    }

    async getProductDetails(storeIdOrSlug, productIdOrSlug, ignoreCache = false) {
        let url = `${this.apiBaseUrl}/stores/${storeIdOrSlug}/search/products/${productIdOrSlug}`;
        const headers = this.getRequestHeaders(this.getAccessToken());

        if (ignoreCache) {
            headers['Cache-Control'] = 'no-cache';
        }

        const response = await axios.get(url, { headers });

        return {
            ok: response.status === 200,
            data: response.data
        };
    }
}