import React from 'react';

export function generateUid() {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
}

export function readErrors(response) {
    if (!Boolean(response) || response.status !== 400 || typeof response.data !== 'object') {
        return null;
    }

    const { data } = response;
    const errors = {};

    for (const key in data) {
        errors[key] = data[key][0];
    }

    return errors;
}

export function toHtmlString(value) {
    return value.split('\n')
        .filter(p => Boolean(p)) // Remove empty paragraphs (double newlines).
        .map((p, i) => <p key={i}>{p}</p>);
}