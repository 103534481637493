import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5)
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    icon: {
        marginRight: theme.spacing(2)
    }
});

class StoreFeatureList extends Component {
    static propTypes = {
        features: PropTypes.array.isRequired,
        detailed: PropTypes.bool.isRequired
    };

    static defaultProps = {
        detailed: false
    };

    render() {
        const { classes, features, detailed } = this.props;

        return <div className={classes.root}>
            {features.map(f => <div key={f.id} className={classes.row}>
                <Icon className={classes.icon} title={f.name}>{f.icon || 'done'}</Icon>
                <div className={classes.col}>
                    <Typography>{f.name}</Typography>
                    {detailed && <Typography variant="body2">{f.notes}</Typography>}
                </div>
            </div>)}
        </div>;
    }
}

export default withStyles(styles)(StoreFeatureList);