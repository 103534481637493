import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import WebsiteIcon from '@material-ui/icons/Web';
import MapIcon from '@material-ui/icons/Place';
import DirectionsIcon from '@material-ui/icons/Directions';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import encodeUrl from 'encodeurl';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& > a:not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    },
    action: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    },
});

class StoreContactActions extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        onPhoneClick: PropTypes.func,
        onWebsiteClick: PropTypes.func,
        onMapClick: PropTypes.func,
        onDirectionsClick: PropTypes.func
    };

    onActionClick = handler => () => {
        if (handler) handler();
    }

    render() {
        const { classes, store } = this.props;

        return <div className={classes.root}>
            {Boolean(store.phoneNumber) && <div className={classes.action}>
                <IconButton component="a"
                    href={'tel:' + store.phoneNumber}
                    onClick={this.onActionClick(this.props.onPhoneClick)}>
                    <PhoneIcon />
                </IconButton>
                <Typography variant="overline">Phone</Typography>
            </div>}
            {Boolean(store.websiteUrl) && <div className={classes.action}>
                <IconButton component="a"
                    href={store.websiteUrl}
                    target="_blank"
                    rel="noopener"
                    onClick={this.onActionClick(this.props.onWebsiteClick)}>
                    <WebsiteIcon />
                </IconButton>
                <Typography variant="overline">Website</Typography>
            </div>}
            {Boolean(store.address) && <div className={classes.action}>
                <IconButton component="a"
                    href={encodeUrl(`https://www.google.com/maps/search/?api=1&query=${store.address}&query_place_id=${store.googlePlaceId}`)}
                    target="_blank"
                    rel="noopener"
                    onClick={this.onActionClick(this.props.onMapClick)}>
                    <MapIcon />
                </IconButton>
                <Typography variant="overline">Map</Typography>
            </div>}
            {Boolean(store.googlePlaceId) && <div className={classes.action}>
                <IconButton component="a"
                    href={encodeUrl(`https://www.google.com/maps/dir/?api=1&destination=${store.name}&destination_place_id=${store.googlePlaceId}`)}
                    target="_blank"
                    rel="noopener"
                    onClick={this.onActionClick(this.props.onDirectionsClick)}>
                    <DirectionsIcon />
                </IconButton>
                <Typography variant="overline">Directions</Typography>
            </div>}
        </div>;
    }
}

export default withStyles(styles)(StoreContactActions);