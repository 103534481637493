import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ProductCard from './ProductCard';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        overflowX: 'auto'
    },
    item: {
        flexShrink: 0
    }
});

function ProductGrid({ store, products, fixed, onSelect }) {
    const classes = useStyles();

    return <Grid container spacing={2} wrap="nowrap" className={classes.root}>
        {products.map(p => <Grid key={p.id}
            item
            className={classes.item}
            xs={fixed ? 5 : 6}
            sm={fixed ? 5 : 4}
            md={fixed ? 5 : 3}
            xl={fixed ? 5 : 2}>
            <ProductCard store={store} product={p} onSelect={() => onSelect(p)} />
        </Grid>)}
    </Grid>;
}

ProductGrid.propTypes = {
    store: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    fixed: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired
};

ProductGrid.defaultProps = {
    fixed: false
};

export default ProductGrid;