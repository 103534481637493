import React, { Component, Fragment } from 'react';
import { Provider, Subscribe } from 'unstated';
import Helmet from 'react-helmet';
import AppContainer from '../../containers/AppContainer';
import StoreContainer from '../../containers/StoreContainer';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import withStyles from '@material-ui/core/styles/withStyles';
import ProductGrid from '../../components/ProductGrid';
import ShareButtons from '../../components/ShareButtons';
import StoreDetails from './StoreDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFound from '../Statuses/NotFound';
import Hidden from '@material-ui/core/Hidden';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { spinnerStyle } from '../../styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2) + 37, // Account for footer height.
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2) + 54 // Account for footer height (double height on xs viewport).
        }
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '100%'
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    title: {
        flex: 1,
        fontFamily: 'Open Sans Condensed',
        fontSize: '2.5rem',
        letterSpacing: 'unset',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: '2rem'
        }
    },
    shareContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    container: {
        display: 'flex'
    },
    products: {
        marginBottom: theme.spacing(2)
    },
    ...spinnerStyle(theme)
});

class Store extends Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        params: PropTypes.object.isRequired,
        storeSearchService: PropTypes.object.isRequired,
        productSearchService: PropTypes.object.isRequired,
        googleApiKey: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.storeContainer = new StoreContainer(props.storeSearchService, props.productSearchService);

        this.onGoBack = this.onGoBack.bind(this);
        this.onOpenNearbyStore = this.onOpenNearbyStore.bind(this);
    }

    state = {
        notFound: false
    };

    async componentDidMount() {
        const { slug, params } = this.props;

        try {
            await this.storeContainer.load(slug, params.q, false);
        } catch (error) {
            if (error.response.status === 404) {
                this.setState({ notFound: true });
            }
        }
    }

    canGoBack() {
        const { location } = this.props;
        const { state } = location;

        return state != null && Boolean(state.canGoBack);
    }

    onGoBack() {
        this.props.history.goBack();
    }

    onOpenProduct(storeSlug, productSlug) {
        this.props.history.push({
            pathname: `/stores/${storeSlug}/products/${productSlug}`,
            state: { canGoBack: true }
        })
    }

    onOpenNearbyStore(ac, thisStoreId, nearbyStoreId, nearbyStoreSlug) {
        ac.onNearbyStoreClicked(thisStoreId, nearbyStoreId);

        this.props.history.push({
            pathname: `/stores/${nearbyStoreSlug}`,
            state: { canGoBack: true }
        });
    }

    render() {
        const { classes, storeSearchService, googleApiKey } = this.props;
        const { notFound } = this.state;

        return <div className={classes.root}>
            <Header storeSearchService={storeSearchService} />
            {!notFound && <Provider inject={[this.storeContainer]}>
                <Subscribe to={[AppContainer, StoreContainer]}>
                    {(ac, sc) => Boolean(sc.state.store) ?
                        <Fragment>
                            <Helmet>
                                <meta name="description" content={sc.state.store.meta.description} />
                                {Boolean(sc.state.store.meta.description) && <meta name="keywords" content={sc.state.store.meta.keywords} />}
                                <meta property="og:url" content={document.location.href} />
                                <meta property="og:title" content={sc.state.store.meta.title} />
                                <meta property="og:description" content={sc.state.store.meta.description} />
                                <title>{sc.state.store.meta.title}</title>
                            </Helmet>
                            <Grid container justify="center" className={classes.gridContainer}>
                                <Hidden smUp>
                                    <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.shareContainer}>
                                        <ShareButtons facebook
                                            twitter
                                            email
                                            title={`${sc.state.store.name} is on Mavitra.`}
                                            url={document.location.href}
                                            size={48}
                                            urlShortener={ac.shortenUrl}
                                            onShare={utms => ac.onShareStoreClicked(sc.state.store.id, utms)} />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className={classes.titleContainer}>
                                    <Hidden xsDown>
                                        {this.canGoBack() && <IconButton color="primary" style={{ marginRight: 8 }} onClick={this.onGoBack}>
                                            <BackIcon fontSize="large" />
                                        </IconButton>}
                                    </Hidden>
                                    <Typography variant="h1" className={classes.title}>{sc.state.store.name}</Typography>
                                    <Hidden xsDown>
                                        <ShareButtons facebook
                                            twitter
                                            email
                                            title={`${sc.state.store.name} is on Mavitra.`}
                                            url={document.location.href}
                                            size={48}
                                            urlShortener={ac.shortenUrl}
                                            onShare={utms => ac.onShareStoreClicked(sc.state.store.id, utms)} />
                                    </Hidden>
                                </Grid>
                                {/* TODO: Lift admin-only condition when ready */}
                                {Boolean(ac.getClaim('admin', false)) && sc.state.products.length > 0 &&
                                    <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                                        <div className={classNames(classes.container, classes.products)}>
                                            <ProductGrid store={sc.state.store} products={sc.state.products} onSelect={p => this.onOpenProduct(sc.state.store.storeSlug, p.slug)} />
                                        </div>
                                    </Grid>}
                                <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                                    <Paper className={classes.container}>
                                        <StoreDetails
                                            store={sc.state.store}
                                            googleApiKey={googleApiKey}
                                            onOpenShoppingLocation={slug => this.props.history.push({
                                                pathname: `/shopping-locations/${slug}`,
                                                state: { canGoBack: true }
                                            })}
                                            onOpenNearbyStore={(id, slug) => this.onOpenNearbyStore(ac, sc.state.store.id, id, slug)} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Fragment> :
                        <CircularProgress className={classes.spinner} size={90} thickness={2.4} />}
                </Subscribe>
            </Provider>}
            {notFound && <NotFound />}
            <Footer solid />
        </div>;
    }
}

export default withRouter(withStyles(styles)(Store));