export const actionsStyle = theme => ({
    actions: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'center'
        },
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    }
});

export const drawerActionsStyle = theme => ({
    drawerActions: {
        alignSelf: 'center',
        marginTop: theme.spacing(2),
        '& > button:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    }
});

export const paragraphsStyle = theme => ({
    paragraphs: {
        '& p:first-of-type': {
            marginTop: 0
        },
        '& p:last-of-type': {
            marginBottom: 0
        }
    }
});

export const spinnerStyle = theme => ({
    spinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: -45,
        marginTop: -45,
        zIndex: theme.zIndex.appBar + 1
    }
});