import { Container } from 'unstated';

export default class ProductContainer extends Container {
    constructor(storeSearchService, productSearchService) {
        super();

        this.storeSearchService = storeSearchService;
        this.productSearchService = productSearchService;
    }

    state = {
        store: null,
        product: null
    };

    async load(storeIdOrSlug, productIdOrSlug, ignoreCache = false, loaded = null) {
        const [storeResponse, productResponse] = await Promise.all([
            this.storeSearchService.getStoreDetails(storeIdOrSlug, ignoreCache),
            this.productSearchService.getProductDetails(storeIdOrSlug, productIdOrSlug, ignoreCache)
        ]);

        const ok = storeResponse.ok && productResponse.ok;

        if (ok) {
            this.setState({
                store: storeResponse.data,
                product: productResponse.data
            }, loaded ? () => loaded(this.state.product) : null);
        }

        return ok;
    }
}