import ApiService from './ApiService';
import axios from 'axios';
import queryString from 'query-string';

export default class ImageSearchService extends ApiService {
    constructor(apiBaseUrl, getAccessToken) {
        super();
        this.apiBaseUrl = apiBaseUrl;
        this.getAccessToken = getAccessToken;
    }

    async analyzeImage(imageUrl) {
        const url = `${this.apiBaseUrl}/image-search`;
        const headers = this.getRequestHeaders(this.getAccessToken());
        const request = { imageUrl };
        const response = await axios.get(url + '?' + queryString.stringify(request), { headers });
        const { status, data } = response;

        return {
            ok: status === 200,
            results: data
        };
    }
}