import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StockStatus from '../../components/StockStatus';

function VariantTable({ attributes, variants, ...rest }) {
    return <Table {...rest}>
        <TableHead>
            <TableRow>
                {attributes.map(a =>
                    <TableCell key={a}>{a}</TableCell>
                )}
                <TableCell>Stock</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {variants.map(v =>
                <TableRow key={v.sku} hover>
                    {attributes.map(a =>
                        <TableCell key={a}>{v.attributeValues[a.toLowerCase()] || '-'}</TableCell>
                    )}
                    <TableCell><StockStatus stockStatus={v.stockStatus} /></TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>
}

VariantTable.propTypes = {
    attributes: PropTypes.array.isRequired,
    variants: PropTypes.array.isRequired
};

export default VariantTable;