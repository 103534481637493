import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import StarRating from './StarRating';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    container: {
        display: 'flex',
        height: 120
    },
    logo: {
        height: 120,
        width: 130,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        padding: theme.spacing(2)
    },
    storeDetails: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    storeName: {
        fontSize: '1.1rem'
    },
    starRating: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0.5)
    },
    overline: {
        lineHeight: 1.9
    }
});

class NearbyStoreCard extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    render() {
        const { classes, store } = this.props;

        return <Card className={classes.card}>
            <CardActionArea className={classes.container}
                component="a"
                href={`${window.location.origin}/stores/${store.slug}`}
                onClick={e => {
                    e.preventDefault();
                    this.props.onSelect(store);
                }}>
                <div className={classes.logo} style={{ backgroundImage: `url("${store.logoImageUrl || '/images/no-image.png'}")` }} />
                <CardContent className={classes.storeDetails}>
                    <Typography noWrap align="center" display="block" variant="h5" className={classes.storeName}>
                        {store.name}
                    </Typography>
                    {Boolean(store.rating) && <div className={classes.starRating}>
                        <StarRating rating={store.rating} />
                    </div>}
                    {Boolean(store.shoppingLocation || store.suburb) &&
                        <Fragment>
                            {Boolean(store.distance) &&
                                <Typography align="center" display="block" variant="overline" className={classes.overline}>
                                    {store.distance && `${store.distance} metres away`}
                                </Typography>}
                        </Fragment>}
                </CardContent>
            </CardActionArea>
        </Card>;
    }
}

export default withStyles(styles)(NearbyStoreCard);